import { useState, useMemo, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import { PaletteMode } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";
import Layout from "./components/Layout";
import { getDesignTokens, ColorModeContext } from "./theme";
import ProductConfigurator from "./pages/admin/product.page";
import PriceConfigurator from "./pages/admin/price.page";
import PromptConfigurator from "./pages/admin/prompt.page";
import Dashboard from "./pages/admin/dashboard.page";
import Subscribelist from "./pages/admin/subscribe.page";
import ContentGenerator from "./pages/admin/blogcreate.page";
import ContentEditor from "./pages/admin/blogedit.page";
import GeniePage from "./pages/genie.page";
import LandingPage from "./pages/landing.page";
import AnycodeLandingPage from "./pages/Anycode_landing";
import SignupPage from "./pages/auth/signup.page";
import TermsPage from "./pages/auth/terms.page";
import SigninPage from "./pages/auth/signin.page";
import ForgotPage from "./pages/auth/forgot.page";
import VerifyPage from "./pages/auth/verify.page";
import RequireUser from "./components/requireUser";
import UnauthorizePage from "./pages/unauthorized.page";
import PricingPage from "./pages/pricing.page";
import PrivacyPage from "./pages/auth/privacy.page";
import FeedbackPage from "./pages/feedback.page";
import PaymentPage from "./pages/payment/payment.page";
import SuccessPage from "./pages/payment/success.page";
import CardUpdatedPage from "./pages/payment/success_card_update.page";
import ADiveBlogs from "./blogs/A_Dive_into_Decentralization.blogs";
import ADiveBlogs1 from "./blogs/A_Dive_into_Decentralization.blogs1";
import ADiveintoBlogs from "./blogs/A_Dive_into.blogs";
import ADiveintoBlogs1 from "./blogs/A_Dive_into.blogs1";
import TheAIAdvantage from "./blogs/The_AI_Advantage.blogs";
import BeyondSecurity from "./blogs/Beyond_Security.blogs";
import UnveilingCodeGenie from "./blogs/Unveiling_Code_Genie.blogs";
import CostEffectiveAuditing from "./blogs/Cost_Effective_Auditing.blogs";
import ANoviceInBlockchain from "./blogs/A_Novice_In_Blockchain.blogs";
import OneClickMultipleSolutions from "./blogs/One_Click_Multiple_Solutions.blogs";
import RealtimeRectifications from "./blogs/Real_time_Rectifications.blogs";
import UpgradingtheUpgradable from "./blogs/Upgrading_the_Upgradable.blogs";
import BreakingDowntheTechnical from "./blogs/Breaking_Down_the_Technical.blogs";
import TheEthicalEdge from "./blogs/The_Ethical_Edge.blogs";
import TheEvolutionofAudits from "./blogs/The_Evolution_of_Audits.blogs";
import BlogIndexPage from "./pages/BlogIndexPage";
import { Sitemap } from "./Sitemap";
import Aboutus from "./pages/auth/Aboutus.page";
import Contactus from "./pages/auth/contactus.page"
import { useParams } from "react-router-dom";
import './assets/css/bootstrap.min.css';
import ContactusPage from "./pages/contactus.page";
import Usersetting from "./pages/Usersetting";
import NotFoundPage from './pages/NotFound';
import Dashboard_tm from './pages/traffic_monster/dashboard_tm';
import Generated_Blogs from "./pages/traffic_monster/generated_blogs";
import Blogs_List from "./pages/traffic_monster/blogs_list";
import TrafficMonsterlandingnew from "pages/traffic_monster/traffic_monster_landing_new";
import Campaign_List from "./pages/traffic_monster/campaigns/campaign_list";
import New_Campaign from "./pages/traffic_monster/campaigns/new_campaign";
import ActivationSuccessPage from "./pages/traffic_monster/activation_success_page";
// import { usePlanDetailsMutation } from "./redux/api/productApi";
import VerifySite from "./pages/verifySite.page";
import ReferrealProcess from "pages/payment/referreal_process";
import TrafficMonsLandingNew from "pages/traffic_monster/landing_page_tm_new";
import PodcastMonsterLanding from "pages/podcast_monster/podcast_monster_landing";
import { getCookie, removeCookie } from "utils/storageUtils";
import OceshaLanding from "pages/ocesha_landing/ocesha_landing_page";
import Ocesha_new_design from "pages/ocesha_landing/ocesha_new_design";
import EditBlog from "pages/ocesha_landing/edit_blog";

function App() {
  const [mode, setMode] = useState<PaletteMode>("dark");
  const userRole = localStorage.getItem('userRole');
  const loggedIn = localStorage.getItem('logged_in');
  const user_email = localStorage.getItem('user_email');
  const location = useLocation();
  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const pathName = location.pathname;
  const domainName = new URL(currentUrl).hostname;



  /* const [planDetails, setPlanDetails] = useState<any>();

  const [getuserPlanDetails] = usePlanDetailsMutation(); */

  /* const colorMode = useMemo(
    () => ({

      toggleColorMode: () => {
        {
          userRole === 'admin' ?
            setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "light" : "light"
            ) : setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "dark" : "dark"
            )
        }
      },
    }),
    []
  ); */

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        const newMode = userRole === 'admin'
          ? 'light'
          : (mode === 'light' ? 'dark' : 'light');

        setMode(newMode);
        localStorage.setItem('theme', newMode);
      },
    }),
    [mode, userRole]
  );

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') as PaletteMode | null;
    if (savedTheme) {
      setMode(savedTheme);
    } else {
      setMode(userRole === 'admin' ? 'light' : 'dark');
    }
  }, [userRole]);



  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const handleUserActivity = () => {
    setLastActiveTime(Date.now());
  };

  const [lastActiveTime, setLastActiveTime] = useState<number>(Date.now());
  const [isRedirecting, setIsRedirecting] = useState(true);

  //redirect to home page if not logged in
  useEffect(() => {
    // Redirect only if not on '/' and the variable is missing

    if (!user_email && getCookie('user_email') && loggedIn == "true") {
      localStorage.setItem('user_email', getCookie('user_email') || "");
    }
    else if (loggedIn != "true" && !user_email && pathName !== '/' && pathName !== '/pricing' && !pathName.includes('sitemap') && 
      pathName !== '/contactus' && pathName !== '/signin' && pathName !== '/signup' && !pathName.includes('PreviewBlogs1') && 
      pathName !== '/terms' && pathName !== '/privacy' && pathName !== '/forgot' && pathName !== '/referral-process' && pathName !== '/blog') {
      navigate('/', { replace: true });
    }
  }, [navigate, pathName]);

  useEffect(() => {
    // Redirection logic
    if (!currentUrl.includes('podcastmonster') &&
      !currentUrl.includes('localhost') &&
      !currentUrl.includes('podcastmonster.ai') &&
      !currentUrl.includes('ocesha') &&
      !pathName.startsWith('/blogs') &&
      !pathName.startsWith('/blog')) {
      window.location.href = `https://${domainName}/blogs`;
    } else {
      setIsRedirecting(false);
    }
  }, [currentUrl, domainName, pathName]);

  /* useEffect(() => {
    if (isRedirecting) return;
    const timeoutDuration = 1200000; // 20 minutes in milliseconds


    pathName.startsWith('/blog')
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    const checkTimeout = () => {
      const currentTime = Date.now();

      if (pathName.startsWith('/success') || pathName.startsWith('/codegenie/new-campaign')) {
        setLastActiveTime(currentTime);
      }
      const elapsedTime = currentTime - lastActiveTime;



      if (elapsedTime >= timeoutDuration && !pathName.startsWith('/success') && !pathName.startsWith('/codegenie/new-campaign')) {
        localStorage.removeItem("msg");
        localStorage.removeItem("complexity");
        localStorage.removeItem("module");
        localStorage.removeItem("subscription_level");
        localStorage.removeItem("free_plan");
        localStorage.removeItem("localStorage_msg");
        localStorage.removeItem("allSet");
        localStorage.removeItem("Blogs Generated");
        localStorage.removeItem("remaining_wish_count");
        localStorage.removeItem("userRole");
        localStorage.removeItem("payment");
        localStorage.removeItem("user_email");
        localStorage.removeItem("logged_in");
        localStorage.removeItem("prevImg");
        localStorage.removeItem("fromUpgrade");
        localStorage.removeItem("blogsCount");
        localStorage.removeItem("Generating Count");
        localStorage.removeItem("finalData");
        localStorage.removeItem("user_pass");
        localStorage.removeItem("fromNewcampaign");
        localStorage.removeItem("campaignid");
        localStorage.removeItem("returningtocampaign");
        localStorage.removeItem('sourceurl');
        localStorage.removeItem('campaignName');
        localStorage.removeItem('paymentOption');
        localStorage.removeItem('autoPublish');
        localStorage.removeItem('limitPublish');
        localStorage.removeItem('campId');
        localStorage.removeItem('campaignid');
        localStorage.removeItem('returningtocampaign');
        localStorage.removeItem('sourceurl');
        localStorage.removeItem('campaignName');
        localStorage.removeItem('paymentOption');
        localStorage.removeItem('autoPublish');
        localStorage.removeItem('limitPublish');
        localStorage.removeItem('smUrlpublish');
        localStorage.removeItem('addTags');
        localStorage.removeItem('captureLeads');
        localStorage.removeItem('newsLetter');
        localStorage.removeItem('twitterBtn');
        localStorage.removeItem('fbBtn');
        localStorage.removeItem('pintrestBtn');
        localStorage.removeItem('linkedinBtn');
        localStorage.removeItem('video');
        localStorage.removeItem('twitterurl');
        localStorage.removeItem('fburl');
        localStorage.removeItem('pintresturl');
        localStorage.removeItem('linkedinurl');
        localStorage.removeItem('owndomain');
        localStorage.removeItem('ChangepayMode');
        localStorage.removeItem('IdChangingMode');
        localStorage.removeItem('user_name');
        localStorage.removeItem('refreshCount');
        localStorage.removeItem('publishingCampId');
        localStorage.removeItem('Authorurl');
        localStorage.removeItem('Authorname');
        localStorage.removeItem('authorDefaultCheck');
        localStorage.removeItem('appsumo_coupon_code');
        localStorage.removeItem('podcastUrl');
        localStorage.removeItem('AlertMessage');
        localStorage.removeItem('CreatingfromLanding');
        localStorage.removeItem('UrlfromLanding');
        localStorage.removeItem('signedup');
        localStorage.removeItem('uploadedvideo');
        localStorage.removeItem('uploadedVideoName');
        localStorage.removeItem('uploadStatus');

        navigate("/signin", { replace: true });
      } else {
        const remainingTime = timeoutDuration - elapsedTime;
        setTimeout(checkTimeout, remainingTime);
      }
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    const timeoutId = setTimeout(checkTimeout, timeoutDuration);

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);

      clearTimeout(timeoutId);
    };
  }, [lastActiveTime, navigate, isRedirecting]); */

  const clearCookies = () => {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name] = cookie.split("=");
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
    }
  };

  useEffect(() => {
    if (isRedirecting) return;
    const timeoutDuration = 1200000; // 20 minutes in milliseconds


    pathName.startsWith('/blog')
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    const checkTimeout = () => {
      const currentTime = Date.now();

      if (pathName.startsWith('/success') || pathName.startsWith('/codegenie/new-campaign')) {
        setLastActiveTime(currentTime);
      }
      const elapsedTime = currentTime - lastActiveTime;


      var a = 1
      var b = 4
      if (elapsedTime >= timeoutDuration && a == b && !pathName.startsWith('/success') && !pathName.startsWith('/codegenie/new-campaign')) {
        clearCookies();
        clearLocalStorage();
        navigate("/signin", { replace: true });
      } else {
        const remainingTime = timeoutDuration - elapsedTime;
        setTimeout(checkTimeout, remainingTime);
      }
    };

    const clearLocalStorage = () => {
      localStorage.removeItem("msg");
      localStorage.removeItem("complexity");
      localStorage.removeItem("module");
      localStorage.removeItem("subscription_level");
      localStorage.removeItem("free_plan");
      localStorage.removeItem("localStorage_msg");
      localStorage.removeItem("allSet");
      localStorage.removeItem("Blogs Generated");
      localStorage.removeItem("remaining_wish_count");
      localStorage.removeItem("userRole");
      localStorage.removeItem("payment");
      localStorage.removeItem("user_email");
      localStorage.removeItem("logged_in");
      localStorage.removeItem("prevImg");
      localStorage.removeItem("fromUpgrade");
      localStorage.removeItem("blogsCount");
      localStorage.removeItem("Generating Count");
      localStorage.removeItem("finalData");
      localStorage.removeItem("user_pass");
      localStorage.removeItem("fromNewcampaign");
      localStorage.removeItem("campaignid");
      localStorage.removeItem("returningtocampaign");
      localStorage.removeItem('sourceurl');
      localStorage.removeItem('campaignName');
      localStorage.removeItem('paymentOption');
      localStorage.removeItem('autoPublish');
      localStorage.removeItem('limitPublish');
      localStorage.removeItem('campId');
      localStorage.removeItem('campaignid');
      localStorage.removeItem('returningtocampaign');
      localStorage.removeItem('sourceurl');
      localStorage.removeItem('campaignName');
      localStorage.removeItem('paymentOption');
      localStorage.removeItem('autoPublish');
      localStorage.removeItem('limitPublish');
      localStorage.removeItem('smUrlpublish');
      localStorage.removeItem('addTags');
      localStorage.removeItem('captureLeads');
      localStorage.removeItem('newsLetter');
      localStorage.removeItem('twitterBtn');
      localStorage.removeItem('fbBtn');
      localStorage.removeItem('pintrestBtn');
      localStorage.removeItem('linkedinBtn');
      localStorage.removeItem('video');
      localStorage.removeItem('twitterurl');
      localStorage.removeItem('fburl');
      localStorage.removeItem('pintresturl');
      localStorage.removeItem('linkedinurl');
      localStorage.removeItem('owndomain');
      localStorage.removeItem('ChangepayMode');
      localStorage.removeItem('IdChangingMode');
      localStorage.removeItem('user_name');
      localStorage.removeItem('refreshCount');
      localStorage.removeItem('publishingCampId');
      localStorage.removeItem('Authorurl');
      localStorage.removeItem('Authorname');
      localStorage.removeItem('authorDefaultCheck');
      localStorage.removeItem('appsumo_coupon_code');
      localStorage.removeItem('podcastUrl');
      localStorage.removeItem('AlertMessage');
      localStorage.removeItem('CreatingfromLanding');
      localStorage.removeItem('UrlfromLanding');
      localStorage.removeItem('signedup');
      localStorage.removeItem('uploadedvideo');
      localStorage.removeItem('uploadedVideoName');
      localStorage.removeItem('uploadStatus');
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    const timeoutId = setTimeout(checkTimeout, timeoutDuration);


    // Clear localStorage when the tab or window is closed
    /* const handleUnload = () => {
      clearLocalStorage();
    }; */
    // window.addEventListener("beforeunload", handleUnload);


    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);

      clearTimeout(timeoutId);
    };
  }, [lastActiveTime, navigate, isRedirecting]);



  const userEmail = localStorage.getItem('user_email') || '';

  if (isRedirecting) {
    return null; // or a loading spinner if desired
  }

  return (

    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <CssBaseline />
        <Routes>
          <Route path="/landingold" element={<LandingPage />} />
          <Route path="/landingnew" element={<TrafficMonsLandingNew />} />
          {/* <Route path="/" element={<TrafficMonsterlandingnew />} /> */}
          <Route path="/" element={<OceshaLanding />} />
          {/* <Route path="/" element={<PodcastMonsterLanding />} /> */}
          <Route path="/Anycode_landing" element={<AnycodeLandingPage />} />
          <Route path="sitemap" element={<Sitemap />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/aboutuspage" element={<Aboutus />} />
          <Route path="/contactus" element={<ContactusPage />} />
          <Route path="/contactuspage" element={<Contactus />} />
          <Route path="/verifySite" element={<VerifySite />} />

          <Route element={<RequireUser allowedRoles={["admin", "user"]} />}>
            <Route path="/" element={<Layout type="user" page="" />}>
              <Route path="codegenie/dashboard" element={<Dashboard_tm />} />
            </Route>
          </Route>
          <Route path="/" element={<Layout type="user" page="pricing" />}>
            <Route path="pricing" element={<PricingPage />} />
          </Route>
          <Route element={<RequireUser allowedRoles={["admin"]} />}>
            <Route path="/admin" element={<Layout type="admin" page="" />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="subscribelist" element={<Subscribelist />} />
              <Route path="config">
                <Route path="products" element={<ProductConfigurator />} />
                <Route path="prompts" element={<PromptConfigurator />} />
                <Route path="prices" element={<PriceConfigurator />} />
              </Route>
              <Route path="content">
                <Route path="edit" element={<ContentEditor />} />
              </Route>
            </Route>
          </Route>
          {loggedIn === "true" && user_email != "" ? (
            <>
              {/* <Route path="/codegenie/generated-blogs" element={<Generated_Blogs />} /> */}
              <Route path="/codegenie/generated-blogs" element={<Ocesha_new_design />} />

              {/* <Route path="/ocesha" element={<Ocesha_new_design />} /> */}
              <Route path="/ocesha-edit-blog" element={<EditBlog />} />


              <Route path="/codegenie/blogs-list" element={<Blogs_List />} />
              {/* {planDetails?.subscription_level === 'level_3' || planDetails?.subscription_level === 'level_6' ? */}
              {/* <> */}
              {/* <Route path="/codegenie/campaign-list" element={<Campaign_List />} /> */}
              <Route path="/codegenie/new-campaign" element={<New_Campaign />} />
              {/* </> */}
              {/* : */}
              {/* <Route path="/notfound" element={<NotFoundPage />} /> */}
              {/* } */}
              <Route path="/activation-success" element={<ActivationSuccessPage />} />
            </>
          ) : (
            <Route path="/signin" element={<SigninPage />} />
          )}
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/signin" element={<SigninPage />} />
          <Route path="/verify" element={<VerifyPage page="signin" setPassCallback={() => true} />} />
          <Route path="/forgot" element={<ForgotPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/checkout" element={<PaymentPage />} />
          <Route path="/notfound" element={<NotFoundPage />} />
          <Route path="/codegenie/settings" element={<Usersetting />} />
          <Route path="/" element={<Layout type="user" page="pricing" />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/referral-process" element={<ReferrealProcess />} />
          <Route path="/card_updated" element={<CardUpdatedPage />} />
          <Route path="/blog/:url.htm" element={<ADiveintoBlogs1 />} />
          <Route path="/blogs1/:url" element={<ADiveBlogs1 />} />
          <Route path="/PreviewBlogs/:blog_id" element={<ADiveintoBlogs />} />
          <Route path="/PreviewBlogs1/:blog_id" element={<ADiveintoBlogs1 />} />
          <Route path="/blogs/The_AI_Advantage" element={<TheAIAdvantage />} />
          <Route path="/blogs/Beyond_Security" element={<BeyondSecurity />} />
          <Route path="/blogs/Unveiling_Code_Genie" element={<UnveilingCodeGenie />} />
          <Route path="/blogs/Cost_Effective_Auditing" element={<CostEffectiveAuditing />} />
          <Route path="/blogs/A_Novice_In_Blockchain" element={<ANoviceInBlockchain />} />
          <Route path="/blogs/One_Click_Multiple_Solutions" element={<OneClickMultipleSolutions />} />
          <Route path="/blogs/Real_time_Rectifications" element={<RealtimeRectifications />} />
          <Route path="/blogs/Upgrading_the_Upgradable" element={<UpgradingtheUpgradable />} />
          <Route path="/blogs/Breaking_Down_the_Technical" element={<BreakingDowntheTechnical />} />
          <Route path="/blogs/The_Ethical_Edge" element={<TheEthicalEdge />} />
          <Route path="/blogs/The_Evolution_of_Audits" element={<TheEvolutionofAudits />} />
          <Route path="/blogs" element={<BlogIndexPage />} />

          <Route path="/unauthorized" element={<UnauthorizePage />} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>

  );
}

export default App;