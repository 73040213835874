import { useState, useContext, useEffect, useMemo } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  SvgIcon,
  FormControlLabel,
  Switch,
  Divider,
} from "@mui/material";
import { MarginTwoTone, Menu as MenuIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";


import { ReactComponent as CrownIcon } from "../../assets/ico_crown.svg";
import { ReactComponent as ProductIcon } from "../../assets/ico_product.svg";
import { ReactComponent as DownIcon } from "../../assets/ico_downarr.svg";
import { getDesignTokens, ColorModeContext } from "../../theme";
import { useLazyGetModulesQuery, useLazyGetProductQuery } from "../../redux/api/productApi";
import { useAppDispatch } from "../../redux/store";
import { setModule } from "../../redux/features/genieSlice";
import { logout } from "../../redux/features/userSlice";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from "@mui/lab";
import { isBrowser } from "react-device-detect";
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { removeCookie } from "utils/storageUtils";
import OceshaLogo from '../../assets/ocesha_images/ocesha_logo.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as SettingsIco } from '../../assets/ocesha_images/settings_ico.svg';
import { ReactComponent as LogoutIco } from '../../assets/ocesha_images/logout_ico.svg';
import { useAppSelector } from "../../redux/store";
import { useGetUserDetailsMutation } from "redux/api/authApi";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function PricingHeader() {
  const [cookies, setCookie] = useCookies(["logged_in"]);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const colorMode = useContext(ColorModeContext);

  const [modules, setModules] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeItem, setActiveItem] = useState(localStorage.getItem('activeItem') || 'dashboard');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [mode, setMode] = useState<PaletteMode>("dark");

  const theme1 = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  const [userDetails, setUserDetails] = useState<any>();
  const userRole = localStorage.getItem('userRole');
  //console.log('role of the user2=>' + userRole);
  const genieSelector = useAppSelector((state) => state.genieState);
  const [getProduct, getProductState] = useLazyGetProductQuery();
  const [getUserDetailsMutation] = useGetUserDetailsMutation();

  const colorMode1 = useMemo(
    () => ({

      toggleColorMode: () => {
        {
          userRole === 'admin' ?
            setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "light" : "light"
            ) : setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "dark" : "dark"
            )
        }
      },
    }),
    []
  );

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [getModules, getState] = useLazyGetModulesQuery();


  useEffect(() => {
    getModules("CodeGenie");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getState.isSuccess)
      setModules(getState.data.filter((item) => item !== ""));
  }, [getState]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    removeCookie("logged_in");
    dispatch(logout());
    navigate("/");
    toast.success("Logout success");
    // localStorage.removeItem("payment");
    localStorage.removeItem("payment");
    localStorage.removeItem("msg");
    localStorage.removeItem("complexity");
    localStorage.removeItem("module");
    localStorage.removeItem("subscription_level");
    localStorage.removeItem("free_plan");
    localStorage.removeItem("user_email");
    // localStorage.removeItem("userRole");

    removeCookie('user_email');
    localStorage.clear();

  }

  const fetchData = async () => {
    try {
      const response = await getUserDetailsMutation({ email: localStorage.getItem('user_email') || '' });

      if ('data' in response) {
        setUserDetails(response.data.data);
        console.log('userDet=>', response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const clearCookies = () => {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name] = cookie.split("=");
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
    }
  };

  const handleItemClick = (itemName: any) => {
    localStorage.setItem('activeItem', itemName);
    setActiveItem(localStorage.getItem('activeItem') ? localStorage.getItem('activeItem') : itemName);
    navigate('/codegenie/generated-blogs');
    if (itemName === 'upgrade') {
      const value = {
        _id: "",
        product_name: "CodeGenie",
        product_module: genieSelector.module ? genieSelector.module : "",
        userid: "",
        productid: ""
      };
      getProduct(value);
      localStorage.setItem('fromUpgrade', 'true');
      navigate('/pricing', { state: { campIdFromUpgrade: localStorage.getItem('campId') || ''/* selectedCampaign */, from_page: 'blog_listing' } });
    } else if (itemName === 'logout') {
      clearCookies();
      localStorage.setItem("logged_in", "false");
      //console.log("loggedin:" + localStorage.getItem("logged_in"));
      dispatch(logout());
      var module = localStorage.getItem("module");
      console.log("module111...." + module);

      toast.success("Logout success");
      // localStorage.removeItem("payment");
      localStorage.removeItem("msg");
      localStorage.removeItem("complexity");
      localStorage.removeItem("module");
      localStorage.removeItem("subscription_level");
      localStorage.removeItem("free_plan");
      // localStorage.removeItem("userRole");
      localStorage.removeItem("localStorage_msg");
      localStorage.removeItem("allSet");
      localStorage.removeItem("Blogs Generated");
      localStorage.removeItem("remaining_wish_count");
      localStorage.removeItem("userRole");
      localStorage.removeItem("payment");
      localStorage.removeItem("user_email");
      localStorage.removeItem("logged_in");
      localStorage.removeItem("prevImg");
      localStorage.removeItem("fromUpgrade");
      localStorage.removeItem("blogsCount");
      localStorage.removeItem("Generating Count");
      localStorage.removeItem("finalData");
      localStorage.removeItem("user_pass");
      localStorage.removeItem("fromNewcampaign");
      localStorage.removeItem("campaignid");
      localStorage.removeItem("returningtocampaign");
      localStorage.removeItem('sourceurl');
      localStorage.removeItem('campaignName');
      localStorage.removeItem('paymentOption');
      localStorage.removeItem('autoPublish');
      localStorage.removeItem('limitPublish');
      localStorage.removeItem('campId');
      localStorage.removeItem('campaignid');
      localStorage.removeItem('returningtocampaign');
      localStorage.removeItem('sourceurl');
      localStorage.removeItem('campaignName');
      localStorage.removeItem('paymentOption');
      localStorage.removeItem('autoPublish');
      localStorage.removeItem('limitPublish');
      localStorage.removeItem('smUrlpublish');
      localStorage.removeItem('addTags');
      localStorage.removeItem('captureLeads');
      localStorage.removeItem('newsLetter');
      localStorage.removeItem('twitterBtn');
      localStorage.removeItem('fbBtn');
      localStorage.removeItem('pintrestBtn');
      localStorage.removeItem('linkedinBtn');
      localStorage.removeItem('video');
      localStorage.removeItem('twitterurl');
      localStorage.removeItem('fburl');
      localStorage.removeItem('pintresturl');
      localStorage.removeItem('linkedinurl');
      localStorage.removeItem('owndomain');
      localStorage.removeItem('ChangepayMode');
      localStorage.removeItem('IdChangingMode');
      localStorage.removeItem('user_name');
      localStorage.removeItem('refreshCount');
      localStorage.removeItem('publishingCampId');
      localStorage.removeItem('Authorurl');
      localStorage.removeItem('Authorname');
      localStorage.removeItem('authorDefaultCheck');
      localStorage.removeItem('appsumo_coupon_code');
      localStorage.removeItem('podcastUrl');
      localStorage.removeItem('AlertMessage');
      localStorage.removeItem('CreatingfromLanding');
      localStorage.removeItem('UrlfromLanding');
      localStorage.removeItem('signedup');
      localStorage.removeItem('uploadedvideo');
      localStorage.removeItem('uploadedVideoName');
      localStorage.removeItem('uploadStatus');
      localStorage.removeItem('editedposthashtags');
      localStorage.removeItem('editedpostblogsummary');
      localStorage.removeItem('editedpostblogtitle');
      localStorage.removeItem('editedposturl');
      localStorage.removeItem('user_email');
      localStorage.removeItem('YoutubeUrlfromLanding');
      localStorage.removeItem('FirstLogin');
      localStorage.removeItem('DirectSignup');
      localStorage.removeItem('FromIndexPage');
      localStorage.removeItem('activeItem');
      localStorage.removeItem('UpdateduserImage');
      removeCookie('user_email');
      localStorage.clear();

      if (module == process.env.REACT_APP_MAIN_PRODUCT_NAME)
        navigate("/Anycode_landing");
      else
        navigate("/");
    }
  };

  const userImage = localStorage.getItem('UpdateduserImage') !== 'undefined' ? localStorage.getItem('UpdateduserImage') : userDetails?.user_image;

  return (
    <>
      {localStorage.getItem('logged_in') === 'true' && localStorage.getItem('fromUpgrade') !== 'true' ?
        <ColorModeContext.Provider value={colorMode1}>
          <ThemeProvider theme={theme1}>
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <a href="/">
                  <img src={OceshaLogo} style={{ marginLeft: '48%' }} />
                </a>
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    fontWeight: 700,
                    color: "inherit",
                    textDecoration: "none",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    navigate("/");
                  }}
                >

                </Typography>

                <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href=""
                  sx={{
                    mr: 2,
                    display: { xs: "flex", md: "none" },
                    flexGrow: 1,
                    fontWeight: 700,
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >

                </Typography>
                <Box sx={{ flexGrow: 8 }} />

                {/* {localStorage.getItem('fromUpgrade') === 'true' ?
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      flexGrow: 4,
                      marginLeft: isBrowser ? '70%' : '',
                      gap: '16px'
                    }}
                  >
                    <button className="button_leniar px-3" onClick={() => {
                      localStorage.setItem('activeItem', 'blogs');
                      navigate('/codegenie/generated-blogs', { state: { 'userEmail': localStorage.getItem('user_email') } })
                    }} style={{ fontSize: '13px' }}><ArrowBackIcon style={{ fontSize: '20px' }} /> Go Back</button>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px', 
                      }}
                    >
                      {userImage ? <img src={userImage} style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover' }} alt="" /> : userDetails?.user_image ? <img src={userDetails?.user_image} style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover' }} alt="" /> : <AccountCircleIcon style={{ alignSelf: 'center' }} />}
                      <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={handleClick} />
                    </Box>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          backgroundColor: '#36393F',
                          color: '#CACBCC',
                          borderRadius: '5px',
                          minWidth: '214px',
                          boxShadow: 'none',
                          border: '1px solid #CACBCC',
                          top: '65px'
                        },
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem style={{ color: '#CACBCC' }} onClick={() => { handleClose(); handleItemClick('settings'); }}>
                        <SettingsIco fontSize="small" style={{ marginRight: '8px' }} />
                        Settings
                      </MenuItem>
                      <MenuItem style={{ color: '#CACBCC' }} onClick={() => { handleClose(); handleItemClick('logout'); }}>
                        <LogoutIco fontSize="small" style={{ marginRight: '8px' }} />
                        Logout
                      </MenuItem>
                    </Menu>
                  </Box> : ''} */}
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  ></Menu>
                </Box>
                {/* <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", md: "flex" },
                    ml: 12,
                    gap: 6,
                  }}
                >
                </Box> */}


              </Toolbar>
            </Container>
            <Divider />
          </ThemeProvider>
        </ColorModeContext.Provider >
        : ''
      }
      {/* <Box sx={{ flexGrow: 0, display: { xs: "none", md: "block" } }}>
            {!(localStorage.getItem("logged_in") == "true") && (
              <>
                <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    localStorage.setItem("module", "SMART CONTRACTS");
                    dispatch(setModule("SMART CONTRACTS"));
                    navigate("/signup");
                  }}
                  >
                  Try Now
                </Button>
                <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    navigate("/signin");
                  }}
                  >
                  Sign in
                </Button>
              </>
            )}
            
            {(localStorage.getItem("logged_in") == "true") && (
              <>
               <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    
                    navigate("/");
                }}
                >
                  Home
                </Button>
                <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={() => {
                    localStorage.setItem("module", "SMART CONTRACTS");
                    dispatch(setModule("SMART CONTRACTS"));
                    navigate("/codegenie/smart_contracts");
                }}
                >
                  Dashboard
                </Button>
                <Button
                  sx={{
                    background: "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                    marginLeft: 5,
                    color: "white",
                    paddingX: 2,
                    paddingY: 1,
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </>
            )}
          </Box> */}
    </>

  );
}
export default PricingHeader;
