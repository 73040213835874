import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";

import {
  IBlogSaveDraft,
  IBlogSavePublish,
  IGenericResponse,
  IGetBlogs,
  IGetBlogsDet,
  IPromptAcceptSchema,
  IPromptRunSchema,
  IGenerateContent,
  IGetBlogsAdmin,
  ExtractDoc,
  IPrompt,
  IProductDet,
  IGenerateBusinessUrlBlogs,
  IGeturlGeneratedBlogs,
  IThumbsUp,
  IThumbsDown,
  IRegenerateBusinessUrlBlogs,
  ICopyShareUrl,
  IPublishToGoogle,
  IPublishedUnpublishedCount,
  IBulkBlogsPublish,
  IcampaignList,
  IcreateCampaign,
  IGetPageForCampaign,
  ICnameEntry,
  IupdatingpublishFlag,
  Searchbyuserid,
  IProductHeadings,
  IGetKeywordTags,
  IGetBlogsByCampaign,
  Iverifyscript,
  IGetBlogGenerationStatus
} from "./types";
import { IPromptSchema } from "../../components/Prompt";
import { userApi } from "./userApi";
import { setComplexity } from "../features/genieSlice";
import { IBlogsUpdate, IGetBlogId, IDeleteBlog } from "./types";
import { BlogUpdate } from "../../pages/admin/blogedit.page";
// import { BlogDraft } from "../../pages/traffic_monster/generated_blogs";
import { BlogDraft } from "../../pages/admin/blogedit.page";
import { BlogPublish } from "../../pages/admin/blogedit.page";
import { GenerateCont } from "../../pages/admin/blogedit.page";
import { CreateBlog } from "pages/traffic_monster/dashboard_tm";

export const promptApi = createApi({
  reducerPath: "promptApi",
  baseQuery: customFetchBase,
  tagTypes: ["Prompt","Products"],
  endpoints: (builder) => ({
    addPrompt: builder.mutation<IGenericResponse, IPromptSchema>({
      query(data) {
        return {
          url: "prompts",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Prompt", id: "LIST" }],
    }),
    getPrompts: builder.query<IPromptAcceptSchema[], string | void>({
      
      query(product_id) {

        let url = 'prompts';
        if (product_id) {
          // If product_id is provided, add it as a query parameter
          url = `prompts?product_id=${product_id}`;
        }
        return {
          url,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IPromptAcceptSchema[] }) =>
        results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),
    updatePrompt: builder.mutation<
      IGenericResponse,
      { id: string; info: IPromptSchema }
    >({
      query({ id, info }) {
        return {
          url: `prompts/${id}`,
          method: "PATCH",
          body: info,
        };
      },
      invalidatesTags: [{ type: "Prompt", id: "LIST" }],
    }),

    getPromptNames: builder.query<
      string[],
      { product_name: string; product_module: string }
    >({
      query({ product_name, product_module }) {
        return {
          url: `prompts/names?product_name=${product_name}&product_module=${product_module}`,
        };
      },
      transformResponse: (results: { data: { prompt_name: string }[] }) =>
        results.data.map((item) => item.prompt_name),
    }),

    getPromptByProductId: builder.query<{data: IPrompt[]}, { product_id: string }>({
      query({ product_id }) {
        return {
          url: `prompts/getPromptDet?product_id=${product_id}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IPrompt[] }) => ({ data: results.data }), 
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),

    runPrompt: builder.mutation<Array<any>, IPromptRunSchema>({
      query(data) {
        return {
          url: "prompts/run",
          method: "POST",
          body: data,
        };
      },
      transformResponse: (results: { msg: Array<any> }) => results.msg,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await dispatch(userApi.endpoints.getMe.initiate(null));
          console.log(data);
          //dispatch(setComplexity(data));
        } catch (error) { }
      },
    }),
    getBlogDetails: builder.query<IGetBlogs[], { limitval: number; skipval: number; promptid?: string, userid?: string; domain: string }>({
    
      query({ limitval, skipval, promptid="", userid="", domain }) {
        console.log("herere...1");
        return {
          url: `prompts/listBlogsByUserID?limitval=${limitval}&skipval=${skipval}&promptid=${promptid}&userid=${userid}&domain=${domain}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogs[] }) => results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),
    getBlogDetailsAdmin: builder.query<IGetBlogsAdmin[], { limitval: number; skipval: number, query: string }>({
    
      query({ limitval, skipval, query }) {
        console.log("herere...1");
        return {
          url: `prompts/listBlogsadmin?limitval=${limitval}&skipval=${skipval}&query=${query}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogsAdmin[] }) => results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),
    getBlogDet: builder.query<{data: IGetBlogsDet[]; nexturl: string;}, { url: string }>({
      query({ url }) {
        return {
          url: `prompts/getBlogDetbyURL?url=${url}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogsDet[], nexturl: string }) => {
        // Simply return both data and nexturl
        return { data: results.data, nexturl: results.nexturl };

      },
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),
    getBlogid: builder.query<{data: IGetBlogId[]; nexturl: string;}, { blog_id: string , campId: string}>({
      query({ blog_id, campId }) {
        return {
          url: `prompts/getBlogDet?blog_id=${blog_id}&campId=${campId}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogId[], nexturl: string }) => {
        // Simply return both data and nexturl
        return { data: results.data, nexturl: results.nexturl };

      },
      providesTags: [{ type: "Prompt", id: "LIST" }],

    }),

    deleteBlog: builder.mutation<IDeleteBlog, { blog_id: string }>({
      query({blog_id}) {
        return {
          url: `prompts/deleteBlog?blog_id=${blog_id}`,
          method: "GET",
        };
      },
    }),

	  SearchbyUserid: builder.mutation<IProductHeadings, {  userid : string, campaignid: string }>({
        query({userid, campaignid}) {
          return {
            url: `prompts/searchbyuserid?userid=${userid}&campaignid=${campaignid}`,
            method: "GET",
          };
        },
      }),

    /* TafficMonster */
    /* generateBusinessBlog: builder.mutation<IGenerateBusinessUrlBlogs, { url: string, userid: string, tone: any, sourcemode: string, videoid: string, product_desc: string, media_file: string }>({
      query({url, userid, tone, sourcemode, videoid, product_desc, media_file}) {
        return {
          url: `prompts/get_page?url=${url}&userid=${userid}&tone=${tone}&sourcemode=${sourcemode}&videoid=${videoid}&product_desc=${product_desc}&media_file=${media_file}`,
          method: "POST",
        };
      },
    }), */

    generateBusinessBlog: builder.mutation<IGenerateBusinessUrlBlogs, CreateBlog>({
      query(data) {
        return {
          url: `prompts/get_page`,
          method: "POST",
          body: data,
        };
      },
    }),

    regenerateBusinessBlog: builder.mutation<IRegenerateBusinessUrlBlogs, { blog_id: string }>({
      query({blog_id}) {
        return {
          url: `prompts/regenerateautoblogcontent?blog_id=${blog_id}`,
          method: "POST",
        };
      },
    }),

    generatedbusinessurlblogs: builder.mutation<IGeturlGeneratedBlogs, { userid: string }>({
      query({userid}) {
        return {
          url: `prompts/listBlogsByUserID?userid=${userid}`,
          method: "GET",
        };
      },
    }),

    savingThumbsUp: builder.mutation<IThumbsUp, { blogid: string }>({
      query({blogid}) {
        return {
          url: `prompts/blog_thums_up?blogid=${blogid}`,
          method: "GET",
        };
      },
    }),

    savingThumbsDown: builder.mutation<IThumbsDown, { blogid: string }>({
      query({blogid}) {
        return {
          url: `prompts/blog_thums_down?blogid=${blogid}`,
          method: "GET",
        };
      },
    }),

    copyurl: builder.mutation<ICopyShareUrl, { blog_id: string }>({
      query({blog_id}) {
        return {
          url: `prompts/generate_static_html?blog_id=${blog_id}`,
          method: "GET",
        };
      },
    }),

    publishtogoogle: builder.mutation<IPublishToGoogle, { blog_id: string, publish_to_ocesha: number, publish_to_code_genie: number }>({
      query({blog_id, publish_to_ocesha, publish_to_code_genie}) {
        return {
          url: `prompts/publish_to_google?blog_id=${blog_id}&publish_to_ocesha=${publish_to_ocesha}&publish_to_code_genie=${publish_to_code_genie}`,
          method: "GET",
        };
      },
    }),

    publishUnpublishCount: builder.mutation<IPublishedUnpublishedCount, { email: string, campaignid: string }>({
      query({email, campaignid}) {
        return {
          url: `prompts/get_published_and_unpublished_count_by_email?email=${email}&campaignid=${campaignid}`,
          method: "GET",
        };
      },
    }),

    publishBulkyBlogs: builder.mutation<IBulkBlogsPublish, { blog_ids: string }>({
      query({blog_ids}) {
        return {
          url: `prompts/generate_static_html_bulk?blog_ids=${blog_ids}`,
          method: "GET",
        };
      },
    }),

    getgeneratedBlogListings: builder.query<IGetBlogsAdmin[], {promptid: string, userid: string, limitval: number, skipval: number }>({
      query({promptid="", userid, limitval, skipval }) {
        return {
          url: `prompts/listBlogsByUserID?promptid=${promptid}&userid=${userid}&limitval=${limitval}&skipval=${skipval}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogsAdmin[] }) => results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),

    getBlogsByCampaign: builder.query<IGetBlogsAdmin[], { campaignid: string, limitval: number, skipval: number }>({
      query({ campaignid, limitval, skipval }) {
        return {
          url: `prompts/listBlogsByCampaignId?campaignid=${campaignid}&limitval=${limitval}&skipval=${skipval}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogsAdmin[] }) => results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),

    getBlogsByCampaignstatus: builder.query<IGetBlogsAdmin[], { campaignid: string, limitval: number, skipval: number, status: string }>({
      query({ campaignid, limitval, skipval, status }) {
        return {
          url: `prompts/listBlogsByCampaignIdwithstatus?campaignid=${campaignid}&limitval=${limitval}&skipval=${skipval}&status=${status}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogsAdmin[] }) => results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),

    getrandomBlogListings: builder.query<IGetBlogsAdmin[], {}>({
      query() {
        return {
          url: `prompts/listRandomBlogs`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IGetBlogsAdmin[] }) => results.data,
      providesTags: [{ type: "Prompt", id: "LIST" }],
    }),

    
    getproductsDet: builder.query<IProductDet[], { user_id: string }>({
      query({ user_id }) {
        return {
          url: `prompts/getProducts?user_id=${user_id}`,
          method: "GET",
        };
      },
      transformResponse: (results: { data: IProductDet[] }) => results.data , 
      providesTags: [{ type: "Products", id: "LIST" }],
    }),
    
    campaignList: builder.mutation<IcampaignList, { email: string }>({
      query({email}) {
        return {
          url: `prompts/list_campaigns?email=${email}`,
          method: "GET",
        };
      },
    }),

    createCampaign: builder.mutation<IcreateCampaign, { campaign_name: string, url: string, email: string, sourcemode: string, videoid: string, product_desc: string }>({
      query({campaign_name, url, email, sourcemode, videoid, product_desc}) {
        return {
          url: `prompts/create_campaign?campaign_name=${campaign_name}&url=${url}&email=${email}&sourcemode=${sourcemode}&videoid=${videoid}&product_desc=${product_desc}`,
          method: "GET",
        };
      },
    }),

    getpagefrocampaign: builder.mutation<IGetPageForCampaign, { email: string, campaign_id: string }>({
      query({ email, campaign_id}) {
        return {
          url: `prompts/get_page_for_campaign?email=${email}&campaign_id=${campaign_id}`,
          method: "GET",
        };
      },
    }),

    getCnameEntries: builder.mutation<ICnameEntry, { email: string, campaignid: string }>({
      query({email, campaignid}) {
        return {
          url: `prompts/getcnameentries?email=${email}&campaignid=${campaignid}`,
          method: "POST",
        };
      },
    }),

    getverifyscript: builder.mutation<Iverifyscript, { userid: string, campaingid: string }>({
      query({userid, campaingid}) {
        return {
          url: `prompts/getVerifyScript?userid=${userid}&campaingid=${campaingid}`,
          method: "POST",
        };
      },
    }),

    checkverified: builder.mutation<Iverifyscript, { userid: string, campaingid: string }>({
      query({userid, campaingid}) {
        return {
          url: `prompts/checkSiteVerfied?userid=${userid}&campaingid=${campaingid}`,
          method: "POST",
        };
      },
    }), 

    updateAutoPublishFlag: builder.mutation<IupdatingpublishFlag, { campaign_id: string, autopublish: number, threeblogperday: number, addtags: number, capturleads: number, twitterurl: string, fburl: string, pintresturl: string, linkedinurl: string, newsletter: number, twitternbutton: number, fbbutton: number, pintrestbutton: number, linkedinbutton: number, video: number, owndomain: number, cta_lable: string, cta_link: string, videotag: string, iframe: number, publish_to_ocesha: number, publish_to_code_genie: number, competetor_search: number  }>({
      query({ campaign_id, autopublish, threeblogperday, addtags, capturleads, twitterurl, fburl, pintresturl, linkedinurl, newsletter, twitternbutton, fbbutton, pintrestbutton, linkedinbutton, video, owndomain, cta_lable, cta_link, videotag, iframe, publish_to_ocesha, publish_to_code_genie, competetor_search  }) {
        return {
          url: `prompts/update_auto_publish_flag?campaign_id=${campaign_id}&autopublish=${autopublish}&threeblogperday=${threeblogperday}&addtags=${addtags}&capturleads=${capturleads}&twitterurl=${encodeURIComponent(twitterurl)}&fburl=${encodeURIComponent(fburl)}&pintresturl=${encodeURIComponent(pintresturl)}&linkedinurl=${encodeURIComponent(linkedinurl)}&newsletter=${newsletter}&twitternbutton=${twitternbutton}&fbbutton=${fbbutton}&pintrestbutton=${pintrestbutton}&linkedinbutton=${linkedinbutton}&video=${video}&owndomain=${owndomain}&cta_lable=${cta_lable}&cta_link=${cta_link}&videotag=${videotag}&iframe=${iframe}&publish_to_ocesha=${publish_to_ocesha}&publish_to_code_genie=${publish_to_code_genie}&competetor_search=${competetor_search}`,
          method: "GET",
        };
      },
    }),

    getTagKeywords: builder.mutation<IGetKeywordTags, { campaignid: string , blogid: string}>({
      query({campaignid, blogid}) {
        return {
          url: `prompts/getKeyWords?campaignid=${campaignid}&blogid=${blogid}`,
          method: "GET",
        };
      },
    }),

    getBlogGenerationStatus: builder.mutation<IGetBlogGenerationStatus, { campaignid: string }>({
      query({campaignid}) {
        return {
          url: `prompts/checkBlogGenerationStatus?campaignid=${campaignid}`,
          method: "GET",
        };
      },
    }),

    /*  */

    BlogDetailsUpdate: builder.mutation<IBlogsUpdate[], BlogUpdate>({
      query(data) {
        return {
          url: "prompts/updateblog",
          method: "POST",
          body: data,
        };
      },
    }),

    BlogDetailsDraft: builder.mutation<IBlogSaveDraft, BlogDraft>({
      query(data) {
        return {
          url: "prompts/savecontentasdraft",
          method: "POST",
          body: data,
        };
      },
    }),

    BlogDetailsPublish: builder.mutation<IBlogSavePublish, BlogPublish>({
      query(data) {
        return {
          url: "prompts/publishcontent",
          method: "POST",
          body: data,
        };
      },
    }),

    GenerateBlogs: builder.mutation<IGenerateContent, {cat: string, title: string, prompt: string}>({
      query({cat, title, prompt}) {
        return {
          url: `prompts/generatecontent?cat=${cat}&title=${title}&prompt=${prompt}`,
          method: "POST",
          /* body: data, */
        };
      },
    }),

    ExtractDoc: builder.mutation<ExtractDoc,{file: any, product_name: string, product_module: string , blog_count: number ,seo_line:string}>({
      query(data) {
        return {
          url: "prompts/extractDoc",
          method: "POST",
          body: data,
        };
      },
    }),


    BlogsCount: builder.query<any, {promptid: string, userid: string, cat: string,  query: string, domain: string}>({
      query({promptid="", userid, cat = "SMART CONTRACTS", query="", domain}){
        return{
          // url: `prompts/listBlogsCount?cat=${cat}&query=${query}`,
          url: `prompts/listBlogsCountByUserID?userid=${userid}&cat=${cat}&query=${query}&domain=${domain}&promptid=${promptid}`,
          method: "GET"
        };
      },
      transformResponse: (results: { data: any }) => results.data,
    providesTags: [{ type: 'Prompt', id: 'LIST' }],
    }),

    campaignBlogsCount: builder.query<any, {campaignid: string, userid: string, cat: string,  query: string, domain: string}>({
      query({campaignid, userid, cat = "SMART CONTRACTS", query="", domain}){
        return{
          // url: `prompts/listBlogsCount?cat=${cat}&query=${query}`,
          url: `prompts/listBlogsCountByCampignID?campaignid=${campaignid}&userid=${userid}&cat=${cat}&query=${query}&domain=${domain}`,
          method: "GET"
        };
      },
      transformResponse: (results: { data: any }) => results.data,
    providesTags: [{ type: 'Prompt', id: 'LIST' }],
    }),
  }),
});

export const {
  useAddPromptMutation,
  useGetPromptsQuery,
  useGetPromptByProductIdQuery,
  useUpdatePromptMutation,
  useGetPromptNamesQuery,
  useRunPromptMutation,
  useGetBlogDetailsQuery,
  useGetBlogDetailsAdminQuery,
  useGetBlogDetQuery,
  useBlogDetailsUpdateMutation,
  useBlogDetailsDraftMutation,
  useBlogDetailsPublishMutation,
  useGenerateBlogsMutation,
  useBlogsCountQuery,
  useGetBlogidQuery,
  useDeleteBlogMutation,
  useSearchbyUseridMutation,
  useExtractDocMutation,
  useGenerateBusinessBlogMutation,
  useGeneratedbusinessurlblogsMutation,
  useGetgeneratedBlogListingsQuery,
  useGetBlogsByCampaignQuery,
  useGetproductsDetQuery,
  useSavingThumbsUpMutation,
  useSavingThumbsDownMutation,
  useRegenerateBusinessBlogMutation,
  useCopyurlMutation,
  usePublishUnpublishCountMutation,
  usePublishBulkyBlogsMutation,
  useGetrandomBlogListingsQuery,
  useCampaignListMutation,
  useCreateCampaignMutation,
  useGetpagefrocampaignMutation,
  useGetCnameEntriesMutation,
  useUpdateAutoPublishFlagMutation,
  useGetTagKeywordsMutation,
  useGetverifyscriptMutation,
  useCheckverifiedMutation,
  useGetBlogGenerationStatusMutation,
  useCampaignBlogsCountQuery,
  useGetBlogsByCampaignstatusQuery,
  usePublishtogoogleMutation
} = promptApi;
