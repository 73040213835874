import React, { useState, useEffect, useRef } from "react";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {
    Chart,
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Filler,
    Title,
    Tooltip,
} from 'chart.js';
import { isMobile } from "react-device-detect";
import { useGetUserDetailsMutation } from '../../redux/api/authApi';
import { usePublishUnpublishCountMutation, useCampaignListMutation } from '../../redux/api/promptApi';
import { usePlanDetailsMutation, useLazyGetProductQuery } from '../../redux/api/productApi';
import { useAppSelector } from "../../redux/store";
import { IPlanDetail } from '../../redux/api/types';
import { getCookie } from "utils/storageUtils";
import { useNavigate, useLocation } from "react-router-dom";
Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Filler, Title, Tooltip);


const OceshaDashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { FromCampaign } = location.state || {};
    const { userEmail } = location.state || {};
    const { FromUpdatedCampaign } = location.state || {};

    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstance = useRef<Chart | null>(null);

    const [userDets, setUserDets] = useState<any>();
    const [publishedUnpublishedCount, setPublishedUnpublishedCount] = useState<any>();
    const [planDetails, setPlanDetails] = useState<any>();
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [campaignListDatas, setCampaignListDatas] = useState<any>();

    const [getUserDetailsMutation] = useGetUserDetailsMutation();
    const [publishUnpublishCountMutation] = usePublishUnpublishCountMutation();
    const [getuserPlanDetails] = usePlanDetailsMutation();
    const genieSelector = useAppSelector((state) => state.genieState);
    const [getProduct, getProductState] = useLazyGetProductQuery();
    const [campaignListMutation] = useCampaignListMutation();

    const useremail = localStorage.getItem('user_email') || ''/* ? localStorage.getItem('user_email') : userEmail ? userEmail : getCookie('user_email') */;

    const fetchData = async () => {
        try {
            const response = await getUserDetailsMutation({ email: useremail || getCookie('user_email') || '' });
            if ('data' in response) {
                setUserDets(response.data.data);
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchingPublishDatas = async (campaignId: string) => {
        try {
            const responseCount = await publishUnpublishCountMutation({ email: useremail || getCookie('user_email') || '', campaignid: campaignId });

            if ('data' in responseCount) {
                const datas = responseCount.data;
                setPublishedUnpublishedCount(datas);
            }
        } catch (error) {
            console.log('Error fetching publishing counts data:', error);
        }
    }

    useEffect(() => {
        fetchingPublishDatas('');
    }, []);

    const fetchPlanDatas = async () => {
        try {
            const response = await getuserPlanDetails({ email: useremail, campaignid: '' });

            if ('data' in response) {
                setPlanDetails(response.data.data);
            }
        } catch (error) {
            //console.log(error);
        }
    }

    useEffect(() => {
        fetchPlanDatas();
    }, []);

    const [plans, setPlans] = useState<IPlanDetail[]>([
        {
            user: 1,
            plan_name: "",
            total_wishes: 0,
            price: "",
            period: "",
            product_id: "",
            price_id: "",
            subscription_level: "",
            feature: [],
            contracts: 0,
            type: "",
            button_text: "",
            save: ''
        },
    ]);

    useEffect(() => {

        const { data } = getProductState;
        if (data?.plan_details) {
            //console.log(data?.plan_details);
            setPlans([...data?.plan_details]);
        }
        else
            setPlans([
                {
                    user: 1,
                    plan_name: "",
                    total_wishes: 0,
                    price: "",
                    period: "",
                    product_id: "",
                    price_id: "",
                    subscription_level: "",
                    feature: [],
                    contracts: 0,
                    type: "",
                    button_text: "",
                    save: ''
                },
            ]);
    }, [getProductState]);

    const campaignRes = async () => {
        try {
            const response = await campaignListMutation({ email: useremail || '' });
            if ('data' in response) {
                setCampaignListDatas(response.data);
                console.log('campaign list response=>', response.data)
            } else {
                //console.error('Error fetching campaign List:', response.error);
            }
        } catch (error) {
            //console.log('Error fetching campaign List:', error);
        }
    }

    useEffect(() => {
        const publishingblogCampId = localStorage.getItem('publishingCampId');
        if (publishingblogCampId) {
            setSelectedCampaign(publishingblogCampId);
            fetchingPublishDatas(publishingblogCampId);
        }
        else if (FromCampaign) {

            setSelectedCampaign(FromCampaign);
            fetchingPublishDatas(FromCampaign);
            localStorage.setItem('campId', FromCampaign);

            const count = 0;
            localStorage.setItem('refreshCount', count.toString());

            const refreshPage = () => {
                const refreshCountStr = localStorage.getItem('refreshCount');
                const refreshCountt = refreshCountStr !== null ? parseInt(refreshCountStr) : 0;

                if (refreshCountt < 1) {
                    setTimeout(() => {
                        localStorage.setItem('refreshCount', (refreshCountt + 1).toString());
                        // window.location.reload();
                        setSelectedCampaign(FromCampaign);
                        fetchingPublishDatas(FromCampaign);

                        // processBlogs();

                    }, 20000);
                }
            };
            refreshPage();

            // **Set FromCampaign to null**
            navigate(location.pathname, {
                replace: true,
                state: { ...location.state, FromCampaign: null },
            });

        } else if (FromUpdatedCampaign) {
            setSelectedCampaign(FromUpdatedCampaign);
            fetchingPublishDatas(FromUpdatedCampaign);
            localStorage.setItem('campId', FromUpdatedCampaign);
        } else if (campaignListDatas && campaignListDatas.length > 0 /* && !selectedCampaign */) {
            const savedCampId = localStorage.getItem('campId');
            if (!savedCampId) {
                // Only set to the 0th index if no other campaign was previously selected
                setSelectedCampaign(campaignListDatas[0].campaign_id);
                fetchingPublishDatas(campaignListDatas[0].campaign_id);
                localStorage.setItem('campId', campaignListDatas[0].campaign_id);
            } else {
                setSelectedCampaign(savedCampId);
                fetchingPublishDatas(savedCampId);
            }
            /* setSelectedCampaign(campaignListDatas[0].campaign_id);
            fetchingPublishDatas(campaignListDatas[0].campaign_id);
            localStorage.setItem('campId', campaignListDatas[0].campaign_id); */

        }
    }, [campaignListDatas, FromCampaign, FromUpdatedCampaign, location, navigate]);

    useEffect(() => {
        campaignRes();
    }, []);

    useEffect(() => {
        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');

            if (ctx) {
                // Destroy the previous chart instance if it exists
                if (chartInstance.current) {
                    chartInstance.current.destroy();
                }

                // Create a new chart instance
                chartInstance.current = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: ['25 Nov.', '01 Dec.', '08 Dec.', '16 Dec.', '23 Dec.', '30 Dec.', '07 Jan.'],
                        datasets: [
                            {
                                label: 'Data',
                                data: [0, 10000, 50000, 70000, 40000, 60000, 100000],
                                borderColor: '#4BA5EB',
                                backgroundColor: 'rgba(75, 165, 235, 0.2)',
                                tension: 0, // Sharp edges (no curve)
                                borderWidth: 2,
                                fill: true,
                            },
                        ],
                    },
                    options: {
                        scales: {
                            x: {
                                type: 'category',
                                ticks: { color: '#fff' },
                                grid: {
                                    color: 'rgba(255, 255, 255, 0.1)', // Column grid lines
                                },
                            },
                            y: {
                                // Set the min and max for the y-axis
                                min: 0,
                                max: 100000,
                                ticks: {
                                    color: '#fff',
                                    // Custom tick values: Only show 0, 10k, 20k, 50k, 100k
                                    callback: function (value) {
                                        // Ensure that value is treated as a number for comparison
                                        const tickValues = [0, 10000, 20000, 50000, 100000];
                                        if (tickValues.includes(Number(value))) {
                                            return value === 10000 ? '10k' :
                                                value === 20000 ? '20k' :
                                                    value === 50000 ? '50k' :
                                                        value === 100000 ? '100k' : value;
                                        }
                                        return ''; // Hide any other ticks
                                    },
                                },
                                grid: {
                                    color: 'rgba(255, 255, 255, 0.1)', // Row grid lines
                                    lineWidth: 1, // Line width for grid lines
                                },
                            },
                        },
                        plugins: {
                            legend: { display: false }, // Hide the legend if not needed
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    }
                });
            }
        }

        // Cleanup function to destroy the chart on component unmount
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
                chartInstance.current = null;
            }
        };
    }, []);

    return (
        <>
            <div style={{ padding: '4%' }}>
                <p style={{ fontWeight: 'bold', fontSize: '32px', color: '#fff' }}>My Dashboard</p>
                <p style={{ fontWeight: 'lighter', fontSize: '14px', color: '#969992' }}>Here you can find detail blog analysis</p>
                <div className="row">
                    <div className="col-12">
                        <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff' }}>Blog Summary</p>
                    </div>
                </div>

                <div className="row" style={{ display: 'flex', gap: '20px' }}>
                    <div className="col-sm-12 col-md-4 col-lg-4" style={{ background: '#15151D', borderRadius: '8px', width: isMobile ? '100%' : '38%' }}>
                        <div style={{ margin: '3%' }}>
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th className="card_headings" colSpan={2}>
                                            Blog Remaining
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="card_val" style={{ width: '35%', position: 'relative', textAlign: 'left' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column'/* , alignItems: 'center' */ }}>
                                                {userDets?.blog_count_balance}
                                                <span className="card_subheadings">Text/Website</span>
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                top: '21%',
                                                right: 0,
                                                height: '74%',
                                                width: '1px',
                                                backgroundColor: '#545253',
                                            }}></div>
                                        </td>
                                        <td className="card_val" style={{ width: '65%', textAlign: 'left', paddingLeft: '8%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column'/* , alignItems: 'center' */ }}>
                                                {userDets?.podcast_count_balance}
                                                <span className="card_subheadings">YouTube/Video/Audio</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4" style={{ background: '#15151D', borderRadius: '8px', width: isMobile ? '100%' : '38%' }}>
                        <div style={{ margin: '3%' }}>
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th className="card_headings" colSpan={2}>
                                            Blog Counts
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="card_val" style={{ width: '100%', position: 'relative', textAlign: 'left' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column'/* , alignItems: 'center' */ }}>
                                                {publishedUnpublishedCount?.total}
                                                <span className="card_subheadings">Total</span>
                                            </div>
                                            {/*  <div style={{
                                                position: 'absolute',
                                                top: '21%',
                                                right: '38px',
                                                height: '74%',
                                                width: '1px',
                                                backgroundColor: '#545253',
                                            }}></div> */}
                                        </td>
                                        {/*  <td className="card_val" style={{ width: '30%', position: 'relative', textAlign: 'left' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {publishedUnpublishedCount?.published}
                                                <span className="card_subheadings">Published</span>
                                            </div>
                                            <div style={{
                                                position: 'absolute',
                                                top: '21%',
                                                right: '15px',
                                                height: '74%',
                                                width: '1px',
                                                backgroundColor: '#545253',
                                            }}></div>
                                        </td>
                                        <td className="card_val" style={{ width: '40%', position: 'relative', textAlign: 'left' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {publishedUnpublishedCount?.unpubhished}
                                                <span className="card_subheadings">Unpublished</span>
                                            </div>
                                        </td> */}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4" style={{ background: '#15151D', borderRadius: '8px', width: isMobile ? '100%' : '20%' }}>
                        <div style={{ margin: '3%' }}>
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th className="card_headings" colSpan={2}>
                                            Current Plan
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '100%', position: 'relative', textAlign: 'left', borderBottom: '1px solid #545253' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: isMobile ? '4%' : '8%', fontWeight: 'bold', color: '#fff', fontSize: '16px' }}>
                                                {planDetails?.plan_name ? planDetails?.plan_name : 'Non-Subscribed'}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100%', position: 'relative', textAlign: 'left' }}>
                                            <div style={{ display: 'flex', fontWeight: 'lighter', color: '#fff', fontSize: '14px', gap: '8px', marginTop: '2%' }}>
                                                {(parseInt(localStorage.getItem('free_plan') || '0') === 0 || parseInt(localStorage.getItem('free_plan') || '1') || (localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_2" || localStorage.getItem("subscription_level") == "level_3" || localStorage.getItem("subscription_level") == "level_4" || localStorage.getItem("subscription_level") == "level_5")) && (
                                                    <button className="button_leniar" style={{ borderRadius: '30px', height: '27px', width: '50%', fontSize: '14px', fontWeight: 'lighter' }}
                                                        onClick={() => {
                                                            const value = {
                                                                _id: "",
                                                                product_name: "CodeGenie",
                                                                product_module: genieSelector.module ? genieSelector.module : "",
                                                                userid: "",
                                                                productid: ""
                                                            };
                                                            getProduct(value);
                                                            localStorage.setItem('fromUpgrade', 'true');
                                                            navigate('/pricing', { state: { campIdFromUpgrade: selectedCampaign, from_page: 'blog_listing' } });
                                                        }}>Upgrade</button>
                                                )}
                                                <button style={{ borderRadius: '30px', height: '27px', width: '50%', fontSize: '14px', fontWeight: 'lighter', color: '#CACBCC', border: '1px solid #545253', background: 'none' }} onClick={() => window.open('/contactus')}>Help</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {!isMobile && (userDets?.email === 'marketing@ocesha.com' || userDets?.email === 'rohan@ocesha.com') ? <div className="row" style={{ marginTop: '3%' }}>
                    <div className="col-sm-12 col-md-8 col-lg-8">
                        <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff' }}>Top Performing Blogs</p>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff' }}>Total Page Views</p>
                    </div>
                </div> : ''}

                <div className="row" style={{ display: 'flex', gap: '14px' }}>

                    {isMobile && (userDets?.email === 'marketing@ocesha.com' || userDets?.email === 'rohan@ocesha.com') ?
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff', marginTop: '5%' }}>Total Page Views</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4" style={{ background: '#15151D', borderRadius: '8px' }}>
                                <div style={{ margin: '3%' }}>
                                    <div className="row" style={{ marginTop: '7%' }}>
                                        <div className="col-8">
                                            <p className="card_headings">Page Views</p>
                                        </div>
                                        <div className="col-4">
                                            <select className="custom-dropdown" style={{ border: 'none' }} defaultValue="Month">
                                                <option value="Month">Month</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <canvas ref={chartRef} style={{ width: '100%', height: '300px' }}></canvas>
                                        </div>
                                        <div className="col-12" style={{ background: '#24242C', borderRadius: '10px', marginTop: '5%' }}>
                                            <p style={{ fontWeight: 'lighter', fontSize: '16px', color: '#D9D9D9', marginTop: '4%' }}>VS Last Month</p>
                                            <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1', marginBottom: '5%' }}>
                                                <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff', display: 'flex', alignItems: 'center', margin: 0 }}>
                                                    +15%
                                                    <span style={{ marginLeft: '4px', display: 'flex', alignItems: 'center' }}>
                                                        <TrendingUpIcon style={{ width: '22px', color: '#7AB65D' }} />
                                                    </span>
                                                </p>
                                                <p style={{ color: '#CACBCC', fontSize: '14px', fontWeight: 'lighter', margin: 0 }}>
                                                    2024
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ background: '#24242C', borderRadius: '10px', marginTop: '2%' }}>
                                            <p style={{ fontWeight: 'lighter', fontSize: '16px', color: '#D9D9D9', marginTop: '4%' }}>Top Month</p>
                                            <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1', marginBottom: '5%' }}>
                                                <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff', display: 'flex', alignItems: 'center', margin: 0 }}>
                                                    November
                                                </p>
                                                <p style={{ color: '#CACBCC', fontSize: '14px', fontWeight: 'lighter', margin: 0 }}>
                                                    2024
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> : ''}

                    {isMobile && (userDets?.email === 'marketing@ocesha.com' || userDets?.email === 'rohan@ocesha.com') ? <div className="row">
                        <div className="col-12">
                            <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff', marginTop: '5%' }}>Top Performing Blogs</p>
                        </div>
                    </div> : ''}
                    
                    {(userDets?.email === 'marketing@ocesha.com' || userDets?.email === 'rohan@ocesha.com') ?
                        <div className="col-sm-12 col-md-8 col-lg-8" style={{ background: '#15151D', borderRadius: '8px', width: isMobile ? '100%' : '65%' }}>
                            <div style={{ margin: '3%' }}>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr style={{ borderBottom: '1px solid #242424' }}>
                                            <th style={{ padding: '6px 0', width: '60%', fontSize: '14px', fontWeight: 600, color: '#fff' }}>
                                                Blogs
                                            </th>
                                            <th style={{ padding: '6px 0', width: '20%', fontSize: '14px', fontWeight: 600, color: '#fff' }}>
                                                Page View
                                            </th>
                                            <th style={{ padding: '6px 0', width: '20%', fontSize: '14px', fontWeight: 600, color: '#fff' }}>
                                                Avg. Engagement Time
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ borderBottom: '1px solid #242424', height: '50px' }}>
                                            <td style={{ padding: '10px 0', width: '60%', textAlign: 'left', color: '#4BA5EB', fontWeight: 'lighter', fontSize: '16px' }}>
                                                {isMobile ? `https://test.podcastmonster.ai/codegenie/undefined?campId=675489a3ef4fca4d8fc4f295`.slice(0, 30) + '...' : `https://test.podcastmonster.ai/codegenie/undefined?campId=675489a3ef4fca4d8fc4f295`.slice(0, 44) + '...'}
                                            </td>
                                            <td style={{ padding: '10px 0', width: '20%', textAlign: 'left', fontWeight: 'lighter', fontSize: '16px', color: '#fff' }}>
                                                40
                                            </td>
                                            <td style={{ padding: '10px 0', width: '20%', textAlign: 'left', fontWeight: 'lighter', fontSize: '16px', color: '#fff' }}>
                                                5m 54s
                                            </td>
                                        </tr>

                                        <tr style={{ borderBottom: '1px solid #242424', height: '50px' }}>
                                            <td style={{ padding: '10px 0', width: '60%', textAlign: 'left', color: '#4BA5EB', fontWeight: 'lighter', fontSize: '16px' }}>
                                                {isMobile ? `https://test.podcastmonster.ai/codegenie/undefined?campId=675489a3ef4fca4d8fc4f295`.slice(0, 30) + '...' : `https://test.podcastmonster.ai/codegenie/undefined?campId=675489a3ef4fca4d8fc4f295`.slice(0, 44) + '...'}
                                            </td>
                                            <td style={{ padding: '10px 0', width: '20%', textAlign: 'left', fontWeight: 'lighter', fontSize: '16px', color: '#fff' }}>
                                                40
                                            </td>
                                            <td style={{ padding: '10px 0', width: '20%', textAlign: 'left', fontWeight: 'lighter', fontSize: '16px', color: '#fff' }}>
                                                5m 54s
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> : ''}

                    {!isMobile && (userDets?.email === 'marketing@ocesha.com' || userDets?.email === 'rohan@ocesha.com') ? <div className="col-sm-12 col-md-4 col-lg-4" style={{ background: '#15151D', borderRadius: '8px' }}>
                        <div style={{ margin: '3%' }}>
                            <div className="row" style={{ marginTop: '7%' }}>
                                <div className="col-8">
                                    <p className="card_headings">Page Views</p>
                                </div>
                                <div className="col-4">
                                    <select className="custom-dropdown" style={{ border: 'none' }} defaultValue="Month">
                                        <option value="Month">Month</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <canvas ref={chartRef} style={{ width: '100%', height: '300px' }}></canvas>
                                </div>
                                <div className="col-12" style={{ background: '#24242C', borderRadius: '10px', marginTop: '5%' }}>
                                    <p style={{ fontWeight: 'lighter', fontSize: '16px', color: '#D9D9D9', marginTop: '4%' }}>VS Last Month</p>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1', marginBottom: '5%' }}>
                                        <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff', display: 'flex', alignItems: 'center', margin: 0 }}>
                                            +15%
                                            <span style={{ marginLeft: '4px', display: 'flex', alignItems: 'center' }}>
                                                <TrendingUpIcon style={{ width: '22px', color: '#7AB65D' }} />
                                            </span>
                                        </p>
                                        <p style={{ color: '#CACBCC', fontSize: '14px', fontWeight: 'lighter', margin: 0 }}>
                                            2024
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12" style={{ background: '#24242C', borderRadius: '10px', marginTop: '2%' }}>
                                    <p style={{ fontWeight: 'lighter', fontSize: '16px', color: '#D9D9D9', marginTop: '4%' }}>Top Month</p>
                                    <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1', marginBottom: '5%' }}>
                                        <p style={{ fontWeight: 'bold', fontSize: '20px', color: '#fff', display: 'flex', alignItems: 'center', margin: 0 }}>
                                            November
                                        </p>
                                        <p style={{ color: '#CACBCC', fontSize: '14px', fontWeight: 'lighter', margin: 0 }}>
                                            2024
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
                </div>
            </div>
        </>
    )
}

export default OceshaDashboard;