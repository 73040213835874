import React, { useState, useEffect, useRef } from "react";
import './ocesha.css';
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ocesha_logo from '../../assets/ocesha_images/ocesha_logo.png';
import background_img from '../../assets/ocesha_images/background.png';
import { Button, Typography, TextareaAutosize, TextField, Paper, Select, Grid, Collapse, Box, MenuItem, CircularProgress } from "@mui/material";
import video from './samplerohanvideo.mp4';
import tool_1 from '../../assets/ocesha_images/tool_1.png';
import tool_2 from '../../assets/ocesha_images/tool_2.png';
import tool_3 from '../../assets/ocesha_images/tool_3.png';
import best_1 from '../../assets/ocesha_images/best_1.png';
import best_2 from '../../assets/ocesha_images/best_2.png';
import best_3 from '../../assets/ocesha_images/best_3.png';
import create_1 from '../../assets/ocesha_images/create_1.png';
import create_2 from '../../assets/ocesha_images/create_2.png';
import create_3 from '../../assets/ocesha_images/create_3.png';
import combined_frame from '../../assets/ocesha_images/combined_frame.png';
import gradient_1 from '../../assets/ocesha_images/gradient_1.png';
import gradient_2 from '../../assets/ocesha_images/gradient_2.png';
import gradient_3 from '../../assets/ocesha_images/gradient_3.png';
import { ReactComponent as LinkIcon } from '../../assets/podcast_monster/link.svg';
import { toast } from "react-toastify";
import perks_1 from '../../assets/ocesha_images/perks_1.png';
import perks_2 from '../../assets/ocesha_images/perks_2.png';
import perks_3 from '../../assets/ocesha_images/perks_3.png';
import perks_4 from '../../assets/ocesha_images/perks_4.png';
import perks_5 from '../../assets/ocesha_images/perks_5.png';
import perks_6 from '../../assets/ocesha_images/perks_6.png';
import help_1 from '../../assets/ocesha_images/helps_1.png';
import help_2 from '../../assets/ocesha_images/helps_2.jpg';
import help_3 from '../../assets/ocesha_images/helps_3.jpg';
import help_4 from '../../assets/ocesha_images/helps_4.jpg';
import help_5 from '../../assets/ocesha_images/helps_5.jpg';
import help_6 from '../../assets/ocesha_images/helps_6.jpg';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import testimonial_1 from '../../assets/ocesha_images/testimonial_1.png';
import testimonial_2 from '../../assets/ocesha_images/testimonial_2.png';
import testimonial_3 from '../../assets/ocesha_images/testimonial_3.png';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import { ReactComponent as CopyRightIcon } from '../../assets/podcast_monster/copyrights_icon.svg';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import TrustPilotLogo from '../../assets/ocesha_images/trustpilot_logo.png';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import { StarHalf } from "@mui/icons-material";

interface Testimonial {
    name: string;
    role: string;
    message: string;
    image: string;
}

const testimonials: Testimonial[] = [
    {
        name: "Rachel P.",
        role: "Small Business Owner",
        message: "AI tools are super intuitive. Perfect for businesses like mine with limited marketing knowledge.",
        image: `${testimonial_1}`
    },
    {
        name: "Mario Rui",
        role: "Marketing Manager, TechCo",
        message: "OceSha has revolutionized our content creation process. AI-powered insights gave us a clear edge over competitors.",
        image: `${testimonial_2}`
    },
    {
        name: "Son Hyung min",
        role: "Entrepreneur",
        message: "Posting on multiple platforms is a breeze with OceSha. It's like having a full-time marketing team!",
        image: `${testimonial_3}`
    },
    {
        name: "Maximillian",
        role: "Blogger",
        message: "OceSha helped us target niche keywords, driving traffic and conversions.",
        image: `${testimonial_1}`
    },
    {
        name: "Maxi",
        role: "Blogger",
        message: "OceSha helped us target niche keywords, driving traffic and conversions.",
        image: `${testimonial_2}`
    },
    {
        name: "Alex",
        role: "Blogger",
        message: "OceSha helped us target niche keywords, driving traffic and conversions.",
        image: `${testimonial_3}`
    },
    {
        name: "Hardin",
        role: "Blogger",
        message: "OceSha helped us target niche keywords, driving traffic and conversions.",
        image: `${testimonial_1}`
    },
];

interface FAQItem {
    question: string;
    answer: string;
}

const faqData: FAQItem[] = [
    {
        question: 'What is OceSha?',
        answer: 'OceSha is a 360-degree AI-powered platform designed for entrepreneurs and agencies to create, distribute, and monetize high-quality content seamlessly. From social media posts to multimodal blogs, OceSha uses cutting-edge AI to simplify your content marketing needs.',
    },
    {
        question: 'How does OceSha optimize content for SEO?',
        answer: 'OceSha integrates advanced SEO techniques into every piece of content it creates. This ensures your content ranks higher on search engines like Google, Bing, and ChatGPT search, driving more visibility and organic traffic to your brand.',
    },
    {
        question: 'What types of content can I create with OceSha?',
        answer: 'With OceSha, you can create a wide variety of content, including social media posts, images, and multimodal blogs enriched with text, visuals, and interactive elements.',
    },
    {
        question: 'Who is OceSha designed for?',
        answer: `OceSha is perfect for entrepreneurs, agencies, and content creators who want to simplify their workflow, grow their brand, and engage their audience effectively. Whether you're running a small business or managing multiple clients, OceSha adapts to your needs.`,
    },
    {
        question: 'How does OceSha handle content distribution?',
        answer: 'OceSha offers seamless content distribution across social media, blogs, and other platforms. With just a click, your content is shared consistently, saving you time and effort while maximizing reach.',
    },
    {
        question: 'Can OceSha help me monetize my content?',
        answer: 'Absolutely! OceSha’s monetization tools are designed to turn your blogs, podcasts, and videos into revenue streams, creating new opportunities for your business.',
    },
    {
        question: 'Is OceSha easy to use?',
        answer: 'Yes! OceSha is user-friendly and intuitive. Its AI-powered tools do the heavy lifting, allowing you to focus on growing your business.',
    },
    {
        question: 'Does OceSha support multiple languages?',
        answer: 'Yes, OceSha supports content creation in multiple languages, helping you reach a global audience.',
    },
    {
        question: 'Is my content secure on OceSha?',
        answer: 'We take security seriously. OceSha ensures your content and data are protected with robust security measures and privacy controls.',
    },
    {
        question: 'How can I get started with OceSha?',
        answer: 'Getting started is easy! Simply sign up for early access on our website or schedule a demo to see OceSha in action. Our team is here to help you every step of the way.',
    }
];

const OceshaLanding = () => {

    const navigate = useNavigate();
    const supportUserid = process.env.REACT_APP_SUPPORT_USER_ID || '';

    const [otherOption, setOtherOption] = useState(false);
    const [userBusinessText, setUserBusinessText] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [businessUrl, setBusinessUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef1 = useRef<HTMLDivElement>(null);
    const [scrollProgress, setScrollProgress] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const targetDivRef = useRef<HTMLDivElement>(null);
    const [openIndex, setOpenIndex] = useState<number | null>(null);
    const [expanded1, setExpanded1] = useState(true);
    const [urlPrefix, setUrlPrefix] = useState<string>(/* 'https://' */'youtubeid');
    const [viewAllFaq, setViewAllFaq] = useState<boolean>(false);
    const [contactname, setContactName] = useState<any>();
    const [contactemail, setContactEmail] = useState<any>();
    const [contactPhno, setContactPhNo] = useState<any>();
    const [contactMessage, setContactMessage] = useState<any>();
    const [isContacting, setIsContacting] = useState<boolean>(false);
    const [isScrapcheckloading, setIsScrapcheckloading] = useState<boolean>(false);
const [srolldynamicContent, setScrolldynamicContent] = useState("OceSha creates SEO-optimized multimedia content designed to attract and convert leads while enabling monetization through increased visibility and audience engagement.");

    const handleTextChange = (event: any) => {
        let inputText = event.target.value;
        setUserBusinessText(inputText);
        localStorage.setItem('CreatingfromLanding', 'true');
        localStorage.setItem('uploadedText', inputText);
    };

    const clearCookies = () => {
        const cookies = document.cookie.split("; ");
        for (const cookie of cookies) {
            const [name] = cookie.split("=");
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
        }
    };

    const handlescrapPage = async (url: string) => {
        setIsScrapcheckloading(true);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.get(
                `${apiUrl}/api/prompts/scrap_page?url=${url}`,
            );
            if (response.data.result === 'failed') {
                toast.error(response.data.data);
            } else {
                handleGenerateBlogClick(url, localStorage.getItem('user_email') || '', userBusinessText, youtubeUrl);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsScrapcheckloading(false);
        }
    }

    const handleUrlPrefixChange = (event: any) => {
        const selectedValue = event.target.value;
        setUrlPrefix(selectedValue);
        console.log('URL Prefix Changed:', selectedValue);
    }

    useEffect(() => {
        console.log('urlPrefix updated:', urlPrefix);
    }, [urlPrefix]);
    const handleGenerateBlogClick = async (url: string, userid: string, uploadedtext: string, youtubeUrl: any) => {
        clearCookies();
        localStorage.clear();
        if (url) {
            localStorage.clear();

            /* let youtubeMatch = url.match(
                /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?.*)?/
            ); */

            // if (youtubeMatch) {
            localStorage.setItem('CreatingfromLanding', 'true');
            localStorage.setItem('UrlfromLanding', url);
            if (userid === '') {
                navigate('/signup', { state: { CreatingfromLanding: localStorage.getItem('CreatingfromLanding'), UrlfromLanding: localStorage.getItem('UrlfromLanding') } });
            }
            /* } else if (!youtubeMatch) {
                toast.error('Please enter valid youtube video url!');
            } */
        } else if (youtubeUrl) {
            const youtubeMatch = youtubeUrl?.match(
                /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|shorts\/|live\/|playlist\?list=)|youtu\.be\/)([a-zA-Z0-9_-]+)/

            );

            if (youtubeMatch) {
                localStorage.setItem('CreatingfromLanding', 'true');
                localStorage.setItem('YoutubeUrlfromLanding', youtubeUrl);
                if (userid === '') {
                    navigate('/signup', { state: { CreatingfromLanding: localStorage.getItem('CreatingfromLanding'), UrlfromLanding: localStorage.getItem('UrlfromLanding'), YoutubeUrlfromLanding: localStorage.getItem('YoutubeUrlfromLanding') } });
                }
            } else if (!youtubeMatch) {
                toast.error('Somethign went wrong, check your url format.');
            }
        }
        else if (/* localStorage.getItem('uploadStatus') == 'success' && */ userBusinessText) {
            localStorage.setItem('CreatingfromLanding', 'true');
            localStorage.setItem('uploadedText', userBusinessText);
            if (userid === '') {
                navigate('/signup', { state: { CreatingfromLanding: localStorage.getItem('CreatingfromLanding'), TextfromLanding: localStorage.getItem('uploadedText')/*  VideofromLanding: localStorage.getItem('uploadedvideo') */ } });
            }
        } else if (!url || !uploadedtext) {
            toast.error('Please enter your business url/upload text!');
        }
    }

    const handleScroll = () => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            const scrollTop = scrollContainer.scrollTop;
            const scrollHeight = scrollContainer.scrollHeight - scrollContainer.clientHeight;
            const progress = (scrollTop / scrollHeight) * 100;
            setScrollProgress(progress);
        }
    };

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
        }
        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, []);

const handleCardClick = (index: any) => {
        setHighlightedIndex(index);

        if (index === 0) {
            setScrolldynamicContent("OceSha creates SEO-optimized multimedia content designed to attract and convert leads while enabling monetization through increased visibility and audience engagement.");
        } else if (index === 1) {
            setScrolldynamicContent("OceSha creates SEO-optimized multimedia content designed to attract and convert leads while enabling monetization through increased visibility and audience engagement.");
        } else if (index === 2) {
            setScrolldynamicContent("OceSha automates content creation, optimization, and distribution, eliminating manual tasks and streamlining workflows for maximum efficiency.");
        } else if (index === 3) {
            setScrolldynamicContent('OceSha leverages AI to tailor content for multiple platforms and formats, ensuring it reaches diverse audiences across social media, blogs, and search engines.');
        } else if (index === 4) {
            setScrolldynamicContent('OceSha produces interactive and visually compelling content that captivates audiences, driving higher levels of interaction and engagement.');
        } else if (index === 5) {
            setScrolldynamicContent('OceSha generates evergreen content that remains relevant over time, helping brands build lasting visibility and authority.');
        } else if (index === 6) {
            setScrolldynamicContent('OceSha offers an intuitive platform with AI-powered tools, making it simple for users to create, edit, and distribute professional content without technical expertise.');
        } else if (index === 7) {
            setScrolldynamicContent('OceSha customizes content based on audience preferences and trends, delivering targeted messaging that resonates and converts.');
        }
    };

    const cards = [
        "Capture leads",
        "Monetize Content",
        "Time and Effort",
        "Broader Audience",
        "Increased Engagement",
        "Long-Term",
        "Easy To Use",
        "Personalized"
    ];


    const handleScroll1 = () => {
        const scrollContainer = scrollContainerRef1.current;
        if (scrollContainer) {
            const scrollTop = scrollContainer.scrollTop;
            const scrollHeight = scrollContainer.scrollHeight - scrollContainer.clientHeight;
            const progress = (scrollTop / scrollHeight) * 100;
            setScrollProgress(progress);
        }
    };

    useEffect(() => {
        const scrollContainer = scrollContainerRef1.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll1);
        }
        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll1);
        };
    }, []);

    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const prevTestimonial = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
        );
    };
    const displayedTestimonials = testimonials.slice(currentIndex, currentIndex + 3);

    const scrollToDiv = () => {
        if (targetDivRef.current) {
            targetDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const toggleAccordion = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    /* const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    }; */

    const handleGetStartedClick = async () => {
        const formData = new FormData();
        formData.append('name', contactname);
        formData.append('email', contactemail);
        formData.append('phone', contactPhno);
        formData.append('subject', contactMessage);
        formData.append('message', contactMessage);
        try {
            setIsContacting(true);
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.post(apiUrl + '/api/auth/contactus', formData, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.data.status === 'success') {
                toast.success(response.data.message);
                setContactName('');
                setContactEmail('');
                setContactPhNo('');
                setContactMessage('');
            } else {
                toast.error('Please try agin later!');
            }
        } catch (error) {
            console.log('Error while contacting', error);
        } finally {
            setIsContacting(false);
        }
    }

    console.log('dropdownval=>', urlPrefix);
    return (
        <>
            <div className="landing">

                <div style={{
                    backgroundImage: `url(${background_img})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '520px',
                    backgroundPosition: 'top right',
                }}>
                    {/* Header */}
                    <div className="container">
                        <div className="row">
                            <nav className="navbar navbar-expand-md">
                                <div className="container-fluid">
                                    <a className="navbar-brand" href="/">
                                        <img src={ocesha_logo} className="img-fluid" alt="" /* style={{ width: '50%' }} */ />
                                    </a>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarScroll"
                                        aria-controls="navbarScroll"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                    >
                                        <span className="">
                                            <i className="bi bi-list text-white" />
                                        </span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarScroll">
                                        <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll ms-md-5  gap-4">
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link nav_active"
                                                    style={{ color: "#4ba5eb", fontWeight: 800 }}
                                                    href="/"
                                                >
                                                    Home
                                                </a>
                                            </li>
                                            <li className="nav-item" onClick={() => {
                                                localStorage.setItem('pricing_from_landing', 'true');
                                                navigate("/pricing");
                                            }} style={{ cursor: 'pointer' }}>
                                                <a className="nav-link nav_simple">
                                                    Pricing
                                                </a>
                                            </li>
                                            {/*   <li className="nav-item" style={{ cursor: 'pointer' }}>
                                                <a className="nav-link nav_simple" href="https://trafficmonsterai.getrewardful.com/signup">
                                                    Affiliates
                                                </a>
                                            </li> */}

                                            <li className="nav-item" onClick={() => {
                                                navigate("/contactus");
                                            }} style={{ cursor: 'pointer' }}>
                                                <a className="nav-link nav_simple">
                                                    Contact
                                                </a>
                                            </li>
                                            <li className="nav-item" onClick={() => {
                                                window.open(`/blogs?userid=${supportUserid}`, '_blank');
                                            }} style={{ cursor: 'pointer' }}>
                                                <a className="nav-link nav_simple">
                                                    Blogs
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="d-flex gap-4" role="search" style={{ justifyContent: isMobile ? 'center' : '' }}>
                                            <button className="btn btn-outline-primary px-3"
                                                onClick={() => {

                                                    if (localStorage.getItem('logged_in') === 'true' && localStorage.getItem('user_email') != "" && localStorage.getItem('user_email') != "null" && localStorage.getItem('user_email') != null) {
                                                        localStorage.setItem('activeItem', 'blogs');
                                                        navigate('/codegenie/generated-blogs');
                                                    } else {
                                                        localStorage.clear();
                                                        navigate("/signin");
                                                    }
                                                }}>Sign  In</button>
                                            <button className="button_leniar px-3" type="submit" onClick={() => {
                                                navigate("/signup", { state: { 'DirectSignup': 'true' } });
                                                localStorage.setItem('DirectSignup', 'true');
                                            }}>
                                                Sign Up
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                            <hr /* className="mt-3" */ style={{ color: "#9aa0a3" }} />
                        </div>
                    </div>
                    {/*  */}

                    <div className="container">
                        <div className="row" style={{
                            width: '80%',
                            margin: '0 auto', // Centers horizontally
                            display: 'flex',
                            justifyContent: 'center', // Aligns content horizontally
                            alignItems: 'center' // Aligns content vertically

                        }} ref={targetDivRef}>
                            <div className="col-12">
                                <p /* className="p1" */ style={{ fontWeight: 'bold', color: '#A38CBB', fontSize: '46px', textAlign: 'center' }}>OCESHA{/* Repurpose Your<br />Videos, Podcasts, and Website<br />Into SEO-Boosting Blogs That Drive Traffic. */}
                                </p>
                                <p /* className="p2" */ style={{ fontWeight: 'bold', fontSize: '33px', color: '#fff', textAlign: 'center' }}>{/* The Ultimate All-In-One AI-Powered Content Creation,<br /> Distribution, and Monetization Platform<br />
                                    For Modern<br />Entrepreneurs and Agencies */}Instantly  Convert Your Videos<br />Audio, Podcasts, Websites, and Documents<br />
                                    To Powerful Blogs</p>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <p style={{ fontWeight: 'bold', fontSize: '28px', color: '#fff', textAlign: 'center', background: '#662D91', width: 'auto', borderRadius: '5px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px' }}>Your All-In-One AI Powered Content Platform</p>
                                </div>
                            </div>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={2} sx={{
                                    color: '#344054', display: urlPrefix === 'text' ? '' : 'flex',
                                    alignItems: urlPrefix === 'text' ? '' : 'center'
                                }}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            padding: 1,
                                            backgroundColor: 'white',
                                            borderRadius: '4px',
                                            width: '100%',
                                        }}
                                    >
                                        <Select
                                            value={urlPrefix}
                                            onChange={handleUrlPrefixChange}
                                            // open={false}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                width: '100%',
                                                height: '35px',
                                                '.MuiInputBase-root': {
                                                    height: '100%'
                                                },
                                                backgroundColor: 'white',
                                                color: '#2A313A',
                                                '& .MuiSelect-icon': {
                                                    color: '#2A313A',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                },
                                            }}
                                        >
                                            <MenuItem value="https://">Website URL</MenuItem>
                                            {/* <MenuItem value="text">Text</MenuItem> */}
                                            <MenuItem value="youtubeid">Youtube Url</MenuItem>
                                            {/* <MenuItem value="upload">Upload Video/Audio File</MenuItem> */}
                                        </Select>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} sm={10}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            padding: 1,
                                            backgroundColor: 'white',
                                            borderRadius: '4px',
                                            width: '100%',
                                        }}
                                    >
                                        {
                                            urlPrefix === 'https://' ?
                                                <TextField
                                                    placeholder="Enter your website url"
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        width: '100%',
                                                        height: '35px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%'
                                                        },
                                                        pointerEvents: 'block'
                                                    }}
                                                    value={businessUrl}
                                                    onChange={(e) => setBusinessUrl(e.target.value)}
                                                // id="sourceurl"
                                                ></TextField> :
                                                <TextField
                                                    placeholder="Enter Your Youtube url"
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        width: '100%',
                                                        height: '35px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%'
                                                        },
                                                        pointerEvents: 'block'
                                                    }}
                                                    value={youtubeUrl}
                                                    onChange={(e) => setYoutubeUrl(e.target.value)}
                                                    id="sourceurl"
                                                ></TextField>}
                                    </Paper>
                                </Grid>
                            </Grid>
                            <div className="col-12" style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                                <button
                                    className="px-3"
                                    type="submit"
                                    style={{
                                        // display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '22px',
                                        fontWeight: 'bold',
                                        width: isMobile ? '74%' : '20%',
                                        borderRadius: '5px',
                                        textAlign: 'center',
                                        filter: isLoading ? 'blur(2px)' : 'none',
                                        pointerEvents: isLoading ? 'none' : 'auto',
                                        height: '65px',
                                        background: '#662D91',
                                        color: '#fff',
                                        cursor: "pointer",
                                        border: 'none'
                                    }}
                                    onClick={() => {
                                        if (businessUrl) {
                                            handlescrapPage(businessUrl);
                                        } else {
                                            handleGenerateBlogClick(businessUrl ? businessUrl : '', localStorage.getItem('user_email') || '', userBusinessText, youtubeUrl);
                                        }
                                    }}
                                    disabled={isLoading}
                                >
                                    {isScrapcheckloading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : <>TRY IT FOR FREE</>}
                                </button>
                            </div>

                            <div className="col-12" style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: '#fff',
                                    width: isMobile ? '40%' : '14%',
                                    gap: '5px',
                                    padding: '10px',
                                    borderRadius: '4px',
                                    cursor: 'pointer'
                                }} onClick={() => window.open('https://www.trustpilot.com/review/ocesha.com', '_blank')}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                        justifyContent: 'center'
                                    }}>
                                        <img src={TrustPilotLogo} style={{ width: '15%' }} />
                                        <span style={{
                                            color: '#000',
                                            fontWeight: 'bold'
                                        }}>
                                            TrustPilot
                                        </span>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        gap: '2px'
                                    }}>
                                        {[...Array(4)].map((_, index) => (
                                            <StarIcon
                                                key={index}
                                                style={{
                                                    color: '#FFD700',
                                                    fontSize: '15px'
                                                }}
                                            />
                                        ))}
                                        <StarHalf style={{
                                            color: '#FFD700',
                                            fontSize: '15px'
                                        }} />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        gap: '2px',
                                        color: '#000',
                                        fontSize: '12px'
                                    }}>
                                        4.5/5
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="row" style={{ height: '40px' }}>
                            <div className="col-sm-12 col-md-4 col-lg-4 typo1">
                                <Typography><span className="span1">People<br />Joined<br /></span><span className="span2">125K+</span></Typography>
                                <Typography><span className="span1">Fraud<br />Probability<br /></span><span className="span2">0,25%</span></Typography>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 btn-align" style={{ gap: isMobile ? '10px' : '5px', marginTop: isMobile ? '7%' : '', height: '40px' }}>
                                <Button sx={{ background: '#662D91', color: '#fff', width: '30%', height: isMobile ? '100%' : '55%', borderRadius: '15px', minHeight: '40px' }} onClick={scrollToDiv}>Create Blog</Button>
                                <Button sx={{ background: '#0A1C28', color: '#fff', width: '30%', height: isMobile ? '100%' : '56%', borderRadius: '15px', minHeight: '40px' }}>How it works</Button>
                            </div>
                            <div className="col-6">
                                &nbsp;
                            </div>
                        </div> */}
                    </div>

                    <div className="mt" style={{
                        backgroundImage: `url(${gradient_1})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '473px',
                        backgroundPosition: 'left',
                    }}>
                        <div className="container card1" style={{ padding: isMobile ? '20px' : '25px' }}>
                            <div className="row">
                                {/* <div className="col-12">
                                    <p style={{ fontSize: '40px', fontWeight: 'bold', color: '#fff' }}>Create Blogs</p>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            background: '#fff',
                                            borderRadius: '8px',
                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                            overflow: 'hidden',
                                            transition: 'max-height 0.3s ease-in-out',
                                            maxHeight: expanded1 ? '600px' : '155px'
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            sx={{
                                                width: '100%',
                                                height: isMobile ? '285px' : '155px',
                                                fontSize: '20px',
                                                fontWeight: 'bold',
                                                borderBottom: expanded1 ? '1px solid #ddd' : 'none',
                                                background: '#D0D5DD',
                                                justifyContent: 'space-between',
                                                color: '#344054',
                                                flexDirection: { xs: 'column', sm: 'row' }, 
                                                alignItems: 'center',
                                                '&:hover': {
                                                    background: '#D0D5DD',
                                                }
                                            }}
                                        
                                        >
                                            <Box sx={{ textAlign: 'left' }}>
                                                Add Your Website URL, Youtube URL, Upload Text, Video file, or Audio file
                                                
                                                <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                                    We will create your blogs based on your uploaded content.
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: { xs: 'block', sm: 'flex' },
                                                    width: isMobile ? '100%' : '24%',
                                                    marginTop: { xs: '10px', sm: '0' },
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    height: '100%',
                                                    background: '#131825',
                                                    padding: '5px'
                                                }}
                                            >
                                                <table style={{ width: isMobile ? '100%' : '325px' }}>
                                                    <thead style={{ paddingBottom: '10px' }}>
                                                        <tr>
                                                            <th colSpan={3} style={{ textAlign: 'center', background: '#662D91', color: '#fff', fontWeight: 'bold' }}>Blogs Remaining</th>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ textAlign: 'center', width: '30%', fontWeight: 'lighter', color: '#fff' }}>Website</th>
                                                            <th style={{ width: '30%' }}>&nbsp;</th>
                                                            <th style={{ textAlign: 'center', width: '30%', fontWeight: 'lighter', cursor: 'pointer', color: '#fff' }}>Video</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textAlign: 'center' }}><span style={{ padding: '2% 19%', borderRadius: '5px', fontSize: '32px', fontWeight: 'bolder', color: '#fff' }}>{'2'}</span></td>
                                                            <td style={{ textAlign: 'center' }}><span style={{ padding: '2% 19%', borderRadius: '5px', fontSize: '25px', fontWeight: 'bolder', color: '#fff' }}>{'Or'}</span></td>
                                                            <td style={{ textAlign: 'center' }}><span style={{ padding: '2% 19%', borderRadius: '5px', fontSize: '32px', fontWeight: 'bolder', color: '#fff' }}>{'2'}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Box>
                                            <Box>
                                                {expanded1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </Box>
                                        </Button>
                                        <Collapse in={expanded1} style={{ marginTop: '2%' }}>
                                            <Box
                                                sx={{
                                                    padding: '16px',
                                                    background: '#f9f9f9',
                                                    borderRadius: '0 0 8px 8px',
                                                    backgroundColor: '#fff'
                                                }}
                                            >

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={2} sx={{
                                                        color: '#344054', display: urlPrefix === 'text' ? '' : 'flex',
                                                        alignItems: urlPrefix === 'text' ? '' : 'center'
                                                    }}>
                                                        <Paper
                                                            elevation={3}
                                                            sx={{
                                                                padding: 1,
                                                                backgroundColor: 'white',
                                                                borderRadius: '4px',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Select
                                                                value={urlPrefix}
                                                                onChange={handleUrlPrefixChange}
                                                                // open={false}
                                                                IconComponent={KeyboardArrowDownIcon}
                                                                sx={{
                                                                    width: '100%',
                                                                    height: '35px',
                                                                    '.MuiInputBase-root': {
                                                                        height: '100%'
                                                                    },
                                                                    backgroundColor: 'white',
                                                                    color: '#2A313A',
                                                                    '& .MuiSelect-icon': {
                                                                        color: '#2A313A',
                                                                    },
                                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'transparent',
                                                                    },
                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'transparent',
                                                                    },
                                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'transparent',
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value="https://">Website URL</MenuItem>
                                                                
                                                                <MenuItem value="youtubeid">Youtube Url</MenuItem>
                                                                
                                                            </Select>
                                                        </Paper>
                                                    </Grid>

                                                    <Grid item xs={12} sm={10}>
                                                        <Paper
                                                            elevation={3}
                                                            sx={{
                                                                padding: 1,
                                                                backgroundColor: 'white',
                                                                borderRadius: '4px',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {
                                                                urlPrefix === 'https://' ?
                                                                    <TextField
                                                                        placeholder="Enter your website url"
                                                                        sx={{
                                                                            backgroundColor: '#fff',
                                                                            width: '100%',
                                                                            height: '35px',
                                                                            '.MuiInputBase-root': {
                                                                                height: '100%'
                                                                            },
                                                                            pointerEvents: 'block'
                                                                        }}
                                                                        value={businessUrl}
                                                                        onChange={(e) => setBusinessUrl(e.target.value)}
                                                                    
                                                                    ></TextField> :
                                                                    <TextField
                                                                        placeholder="Enter Your Youtube url"
                                                                        sx={{
                                                                            backgroundColor: '#fff',
                                                                            width: '100%',
                                                                            height: '35px',
                                                                            '.MuiInputBase-root': {
                                                                                height: '100%'
                                                                            },
                                                                            pointerEvents: 'block'
                                                                        }}
                                                                        value={youtubeUrl}
                                                                        onChange={(e) => setYoutubeUrl(e.target.value)}
                                                                        id="sourceurl"
                                                                    ></TextField>}
                                                        </Paper>
                                                    </Grid>
                                                </Grid>


                                                <Grid container spacing={2} sx={{ marginTop: '1px' }}>
                                                    <Grid item xs={12} sm={2}>
                                                        <Paper
                                                            elevation={3}
                                                            sx={{
                                                                padding: 1,
                                                                backgroundColor: 'white',
                                                                borderRadius: '4px',
                                                                width: '100%',
                                                                height: '100%',
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}
                                                        >
                                                            <Typography sx={{ color: '#000' }}>How many blogs?</Typography>
                                                        </Paper>
                                                    </Grid>

                                                    <Grid item xs={12} sm={10}>
                                                        <Paper
                                                            elevation={3}
                                                            sx={{
                                                                padding: 1,
                                                                backgroundColor: 'white',
                                                                borderRadius: '4px',
                                                                width: isMobile ? '100%' : '12%',
                                                                marginTop: '1px'
                                                            }}
                                                        >
                                                            <TextField
                                                                placeholder="Enter Count"
                                                                sx={{
                                                                    backgroundColor: '#fff',
                                                                    width: '100%',
                                                                    height: '35px',
                                                                    '.MuiInputBase-root': {
                                                                        height: '100%'
                                                                    },
                                                                    pointerEvents: 'none' 
                                                                }}
                                                                inputProps={{
                                                                    inputMode: 'numeric',
                                                                    pattern: '[0-9]*'
                                                                }}
                                                                value={'2'}
                                                            
                                                            ></TextField>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                    </Box>
                                </div> */}
                                {/* <div className="col-12" style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
                                    <button
                                        className="px-3"
                                        type="submit"
                                        style={{
                                            // display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '22px',
                                            fontWeight: 'bold',
                                            width: isMobile ? '70%' : '20%',
                                            borderRadius: '5px',
                                            textAlign: 'center',
                                            filter: isLoading ? 'blur(2px)' : 'none',
                                            pointerEvents: isLoading ? 'none' : 'auto',
                                            height: '65px',
                                            background: '#662D91',
                                            color: '#fff',
                                            cursor: "pointer",
                                            border: 'none'
                                        }}
                                        onClick={() => {
                                            if (businessUrl) {
                                                handlescrapPage(businessUrl);
                                            } else {
                                                handleGenerateBlogClick(businessUrl ? businessUrl : '', localStorage.getItem('user_email') || '', userBusinessText, youtubeUrl);
                                            }
                                        }}
                                        disabled={isLoading}
                                    >
                                        {isScrapcheckloading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : <>CREATE 2 FREE BLOGS<br /><span style={{ fontSize: '10px', marginTop: '-5px', display: 'flex', justifyContent: 'center' }}>FROM YOUTUBE OR YOUR WEBISTE</span></>}
                                    </button>
                                </div> */}

                                <div style={{/*  background: '#fff', */ borderRadius: '10px', padding: '1%', marginTop: '2%' }}>
                                    <div className="col-12" style={{ textAlign: 'center' }}>
                                        <video
                                            style={{ borderRadius: '24px' }}
                                            src={video}
                                            width="80%"
                                            poster="/images/samplerohanvideo.png"
                                            controls
                                        >
                                        </video>
                                    </div>
                                </div>

                                <div className="col-12" style={{ marginTop: '2%' }}>
                                    <p className="p3">The Only Transformation Tool You Need</p><br />
                                    <p style={{ textAlign: isMobile ? 'left' : 'justify' }}>OceSha combines powerful AI technology with smart strategies to help businesses grow their online reach. Whether it's creating engaging social media content or boosting your website’s visibility with SEO, OceSha makes sure your audience finds you quickly and stays engaged.</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <p className="tool_p">AI Agent Technology</p>
                                    <img src={tool_1} style={{ width: isMobile ? '100%' : '', maxWidth: isMobile ? '100%' : '' }} />
                                    <p className="tool_summary" style={{ textAlign: isMobile ? 'left' : 'justify' }}>Generate social media posts, and<br style={{ display: isMobile ? 'none' : 'block' }} />multimodal blogs—all tailored to your brand<br style={{ display: isMobile ? 'none' : 'block' }} />and audience.</p>
                                    {/* <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px' }} onClick={scrollToDiv}>Try It Free</Button> */}
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <p className="tool_p">&nbsp;</p>
                                    <img src={tool_2} style={{ width: isMobile ? '100%' : '80%', height: '194px', maxWidth: isMobile ? '100%' : ''  }} />
                                    <p className="tool_summary" style={{ textAlign: isMobile ? 'left' : 'justify' }}>Every piece of content is infused with<br style={{ display: isMobile ? 'none' : 'block' }} />advanced SEO techniques, ensuring high<br style={{ display: isMobile ? 'none' : 'block' }} />visibility across search engines.</p>
                                    {/* <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px' }} onClick={scrollToDiv}>Try It Free</Button> */}
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <p className="tool_p">Navigating the AI Frontier</p>
                                    <img src={tool_3} style={{ width: isMobile ? '100%' : '', maxWidth: isMobile ? '100%' : '' }} />
                                    <p className="tool_summary" style={{ textAlign: isMobile ? 'left' : 'justify' }}>Our platform doesn’t stop at creation.<br style={{ display: isMobile ? 'none' : 'block' }} />OceSha distributes your content across<br style={{ display: isMobile ? 'none' : 'block' }} />social media,</p>
                                    {/* <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px' }} onClick={scrollToDiv}>Try It Free</Button> */}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2%' }}>
                                    <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px', width: isMobile ? '100%' : '15%' }} onClick={scrollToDiv}>Try It Free</Button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <p style={{ fontSize: '32px', fontWeight: 400, marginTop: '1%' }}>OceSha’s Expertise</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt" style={{
                    backgroundImage: `url(${gradient_2})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '320px',
                    backgroundPosition: 'right',
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                                <p className="gradient-text" style={{ textAlign: isMobile ? 'left' : 'justify' }}>What OceSha Does Best</p><br />
                            </div>
                            <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                                <p style={{ fontWeight: 400, fontSize: '20px', textAlign: isMobile ? 'left' : 'justify' }}>Explore the services and solutions that make OceSha a leader in digital transformation.</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <p className="tool_p">Perfect Posts</p>
                                <img src={best_1} style={{ width: isMobile ? '100%' : '', maxWidth: isMobile ? '100%' : '' }} />
                                <p className="tool_summary" style={{ textAlign: isMobile ? 'left' : 'justify' }}>OceSha crafts impactful social and blog<br style={{ display: isMobile ? 'none' : 'block' }} />content optimized for engagement across<br style={{ display: isMobile ? 'none' : 'block' }} />all major platforms.</p>
                                {/* <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px' }} onClick={scrollToDiv}>Try It Free</Button> */}
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <p className="tool_p">Smarter SEO</p>
                                <img src={best_2} style={{ width: isMobile ? '100%' : '', maxWidth: isMobile ? '100%' : '' }} />
                                <p className="tool_summary" style={{ textAlign: isMobile ? 'left' : 'justify' }}>Advanced AI tools analyze trends to elevate<br style={{ display: isMobile ? 'none' : 'block' }} />your content visibility and search engine<br style={{ display: isMobile ? 'none' : 'block' }} />rankings.</p>
                                {/* <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px' }} onClick={scrollToDiv}>Try It Free</Button> */}
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <p className="tool_p">AI-Powered Insights</p>
                                <img src={best_3} style={{ width: isMobile ? '100%' : '', maxWidth: isMobile ? '100%' : '' }} />
                                <p className="tool_summary" style={{ textAlign: isMobile ? 'left' : 'justify' }}>Competitor research and keyword<br style={{ display: isMobile ? 'none' : 'block' }} />identification drive smarter strategies for<br style={{ display: isMobile ? 'none' : 'block' }} />content success.</p>
                                {/* <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px' }} onClick={scrollToDiv}>Try It Free</Button> */}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2%' }}>
                                <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px', width: isMobile ? '100%' : '15%' }} onClick={scrollToDiv}>Try It Free</Button>
                            </div>
                        </div>
                    </div>

                    <div className="container mt">
                        <div className="row">
                            <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                                <p className="gradient-text" style={{ textAlign: isMobile ? 'left' : 'justify' }}>What Does OceSha Create?</p><br />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <p className="tool_p">Dynamic Content Solutions</p>
                                <img src={create_1} style={{ width: isMobile ? '100%' : '', maxWidth: isMobile ? '100%' : 'none' }} />
                                <p className="tool_summary" style={{ textAlign: isMobile ? 'left' : 'justify' }}>OceSha designs engaging blogs, social<br style={{ display: isMobile ? 'none' : 'block' }} />posts, and SEO-rich multimedia for<br style={{ display: isMobile ? 'none' : 'block' }} />maximum audience impact.</p>
                                {/* <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px' }} onClick={scrollToDiv}>Try It Free</Button> */}
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <p className="tool_p">Optimized Media Strategies </p>
                                <img src={create_2} style={{ width: isMobile ? '100%' : '', maxWidth: isMobile ? '100%' : '' }} />
                                <p className="tool_summary" style={{ textAlign: isMobile ? 'left' : 'justify' }}>Tailored post for Facebook, Instagram<br style={{ display: isMobile ? 'none' : 'block' }} />Twitter/X, and LinkedIn ensure your brand<br style={{ display: isMobile ? 'none' : 'block' }} />thrives on every platform.</p>
                                {/* <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px' }} onClick={scrollToDiv}>Try It Free</Button> */}
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <p className="tool_p">All in One Content Creation</p>
                                <img src={create_3} style={{ width: isMobile ? '100%' : '', maxWidth: isMobile ? '100%' : '' }} />
                                <p className="tool_summary" style={{ textAlign: isMobile ? 'left' : 'justify' }}>Convert podcasts, Audio, Video into<br style={{ display: isMobile ? 'none' : 'block' }} />Engaging blogs to extend your reach.<br style={{ display: isMobile ? 'none' : 'block' }} />Convert website and other content to<br style={{ display: isMobile ? 'none' : 'block' }} />capture leads and drive traffic.</p>
                                {/* <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px' }} onClick={scrollToDiv}>Try It Free</Button> */}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2%' }}>
                                <Button sx={{ color: '#fff', background: '#FF0099', fontWeight: 400, fontSize: '16px', borderRadius: '8px', width: isMobile ? '100%' : '15%' }} onClick={scrollToDiv}>Try It Free</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt" style={{
                    backgroundImage: `url(${gradient_3})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '402px',
                    backgroundPosition: 'left',
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <p style={{ fontWeight: 400, fontSize: '40px', color: '#A38CBB', textAlign: isMobile ? 'left' : 'justify' }}>Why Choose OceSha?</p>
                                <p style={{ color: '#B4A5C3', fontSize: '18px', fontWeight: 400, textAlign: 'left' }}>OceSha is the ultimate platform for<br />businesses looking to streamline content<br />creation and elevate
                                    their marketing<br />strategies. Powered by advanced AI, it helps<br />you generate blogs, optimize keywords,
                                    and<br />create SEO strategies in just seconds. Its<br />unmatched capabilities make it a reliable tool for
                                    businesses aiming to stand out. <br /><br />What sets OceSha apart is its adaptability.<br />Whether you need
                                    image optimization or<br />long-tail keyword integration, OceSha<br />customizes its solutions to fit your
                                    unique<br />goals. This tailored approach ensures every<br />aspect of your content strategy aligns<br />perfectly
                                    with your business needs. <br /><br />Ease of use is another key advantage. With a<br />user-friendly interface,
                                    OceSha breaks down<br />technical barriers, allowing anyone to<br />produce professional-quality content<br />
                                    effortlessly. Whether you’re a seasoned<br />marketer or just starting out, OceSha<br />empowers you to
                                    achieve remarkable  results<br />with  minimal effort.</p>
                                <Button variant="outlined" sx={{ 
                                border: '1px solid #fff', color: '#fff', margin: isMobile ? '0 auto' : '0', display: isMobile ? 'block' : 'inline-block' }} onClick={scrollToDiv}>Try It Free</Button>
                                 </div>

                            <div className="col-sm-12 col-md-8 col-lg-8" style={{ display: 'flex', alignItems: 'center', marginTop: isMobile ? '5%' : '' }}>
                                <img src={combined_frame} style={{ width: '100%', maxWidth: isMobile ? '100%' : '' }} />
                            </div>
                        </div>
                    </div>

                    <div className="container mt">
                        <div className="row">
                            <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                                <p className="gradient-text" style={{ textAlign: isMobile ? 'left' : 'justify' }}>What’s the Perks?</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ position: 'relative', overflow: 'hidden', marginTop: isMobile ? '4%' : '' }}>
                                <img src={perks_1} style={{
                                    width: '100%', borderRadius: '6px'/* , border: '2px solid',
                                borderImage: 'linear-gradient(to bottom right, #7407FF,#0000, #2E35D7) 1' */, maxWidth: isMobile ? '100%' : ''
                                }} />
                                <div style={{
                                    position: 'absolute',
                                    bottom: '0px',
                                    left: '10px',
                                    color: '#fff',
                                    padding: isMobile ? '10px' : '20px',
                                    borderRadius: '5px',
                                    fontSize: isMobile ? '14px' : '25px',
                                    width: 'calc(100% - 20px)',
                                    boxSizing: 'border-box',
                                }}>
                                    <p>Enhanced Performance</p>
                                    <p style={{ fontSize: isMobile ? '12px' : '16px', color: '#fff' }}>OceSha improves SEO rankings with optimized tags, long-tail keywords, and AI-assisted content structuring.</p>
                                </div>
                              </div>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ position: 'relative', overflow: 'hidden', marginTop: isMobile ? '4%' : '' }}>
                                <img src={perks_2} style={{ width: '100%', borderRadius: '6px', maxWidth: isMobile ? '100%' : '' }} />
                                <div style={{
                                    position: 'absolute',
                                    bottom: '0px',
                                    left: '10px',
                                    color: '#fff',
                                    padding: isMobile ? '10px' : '20px',
                                    borderRadius: '5px',
                                    fontSize: isMobile ? '14px' : '25px',
                                    width: 'calc(100% - 20px)',
                                    boxSizing: 'border-box',
                                }}>
                                    <p>Simplified Processes</p>
                                    <p style={{ fontSize: isMobile ? '12px' : '16px', color: '#fff' }}>Effortless integration with social platforms ensures your posts are impactful, efficient, and effective.</p>
                                </div>
                             </div>
                        </div>
                        <div className="row" style={{ marginTop: '2%' }}>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ position: 'relative', overflow: 'hidden', marginTop: isMobile ? '4%' : '' }}>
                                <img src={perks_3} style={{ width: '100%', maxWidth: isMobile ? '100%' : '' }} />
                                <div style={{
                                    position: 'absolute',
                                    bottom: '0px',
                                    left: '10px',
                                    color: '#fff',
                                    padding: isMobile ? '10px' : '20px',
                                    borderRadius: '5px',
                                    fontSize: isMobile ? '14px' : '25px',
                                    width: 'calc(100% - 20px)',
                                    boxSizing: 'border-box',
                                }}>
                                    <p>Optimized for lead Generation</p>
                                    <p style={{ fontSize: isMobile ? '12px' : '16px', color: '#fff' }}>Engage your audience like never before with blogs  enhanced with text, images, videos,  lead capture form and interactive media.</p>
                                </div>
                          </div>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ position: 'relative', overflow: 'hidden', marginTop: isMobile ? '4%' : '' }}>
                                <img src={perks_4} style={{ width: '100%', borderRadius: '6px', maxWidth: isMobile ? '100%' : '' }} />
                                <div style={{
                                    position: 'absolute',
                                    bottom: isMobile ? '-17px' : '0px',
                                    left: '10px',
                                    color: '#fff',
                                    padding: isMobile ? '10px' : '20px',
                                    borderRadius: '5px',
                                    fontSize: isMobile ? '14px' : '25px',
                                    width: 'calc(100% - 20px)',
                                    boxSizing: 'border-box',
                                }}>
                                    <p>AI Agents Built for the Future </p>
                                    <p style={{ fontSize: isMobile ? '12px' : '16px', color: '#fff' }}>Our intelligent AI Agents are equipped with the latest advancement in AI to craft content that resonates with your audience while staying ahead of digital treads.</p>
                                </div>
                              </div>
                        </div>
                        <div className="row" style={{ marginTop: '2%' }}>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ position: 'relative', overflow: 'hidden', marginTop: isMobile ? '4%' : '' }}>
                                <img src={perks_5} style={{ width: '100%', maxWidth: isMobile ? '100%' : '' }} />
                                <div style={{
                                    position: 'absolute',
                                    bottom: isMobile ? '-24px' : '-25px',
                                    left: '10px',
                                    color: '#fff',
                                    padding: isMobile ? '10px' : '20px',
                                    borderRadius: '5px',
                                    fontSize: isMobile ? '14px' : '25px',
                                    width: 'calc(100% - 20px)',
                                    boxSizing: 'border-box',
                                }}>
                                    <p>Effortless Content Distribution</p>
                                    <p style={{ fontSize: isMobile ? '12px' : '16px', color: '#fff' }}>our platform doesn't stop at creation. OceSha distributes your content across social media, blogs, and other platforms with a single click. Stay consistent, Save time, and ensure your message reaches the right audience.</p>
                                </div>
                        </div>
                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ position: 'relative', overflow: 'hidden', marginTop: isMobile ? '4%' : '' }}>
                                <img src={perks_6} style={{ width: '100%', borderRadius: '6px', maxWidth: isMobile ? '100%' : '' }} />
                                <div style={{
                                    position: 'absolute',
                                    bottom: '0px',
                                    left: '10px',
                                    color: '#fff',
                                    padding: isMobile ? '10px' : '20px',
                                    borderRadius: '5px',
                                    fontSize: isMobile ? '14px' : '25px',
                                    width: 'calc(100% - 20px)',
                                    boxSizing: 'border-box',
                                }}>
                                    <p>Monetization Made Simple</p>
                                    <p style={{ fontSize: isMobile ? '12px' : '16px', color: '#fff' }}>Turn content into cash. OceSha's tools help you monetize your blogs, podcasts, and videos, creating new revenue streams for your business.</p>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>

                <div className="container mt">
                    <div className="row">
                        <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                            <p className="p5"><span style={{ color: '#02FBFC' }}>OCE</span><span style={{ color: '#662D91' }}>SHA HELPS</span></p>
                        </div>
                        <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                            <p style={{ fontSize: '20px', textAlign: 'center' }}>OceSha helps Podcast hosts and guests maximize their reach, efficiency, and impact with AI-driven<br />conversions tailored to their unique needs.</p>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: '6%' }}>
                        <div className="col-sm-12 col-md-4 col-lg-4" style={{ display: isMobile ? 'flex' : '', justifyContent: isMobile ? 'center' : '', alignItems: isMobile ? 'center' : '' }}>
                            <div className="scrollWrapper">
                                <div className="customScrollbar">
                                    <div
                                        className="scrollThumb"
                                        style={{ top: `${scrollProgress}%` }}
                                    ></div>
                                </div>

                                <div ref={scrollContainerRef} className="scrollContainer">
                                    <div className="scrollContent">
                                        {cards.map((card, index) => (
                                            <div
                                                key={index}
                                                className={`card ${highlightedIndex === index ? "highlighted" : ""}`}
                                                onClick={() => handleCardClick(index)}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {card.split("\n").map((line, i) => (
                                                    <div key={i}>{line}</div>
                                                ))}
                                            </div>
                                        ))}
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-8" style={{ marginTop: isMobile ? '5%' : '' }}>
                            <img src={highlightedIndex === 0 ? help_1 : highlightedIndex === 1 ? help_2 : highlightedIndex === 2 ? help_3 : highlightedIndex === 3 ? help_4 : highlightedIndex === 4 ? help_5 : help_6} style={{ width: /* isMobile ? '100%' : */ '100%', maxWidth: isMobile ? '100%' : '' }} />
                            <p style={{ fontSize: '20px', textAlign: isMobile ? 'left' : 'justify' }}>{srolldynamicContent}</p>
                            <Button sx={{ background: '#662D91', color: '#fff', marginTop: '5%', margin: isMobile ? '0 auto' : '0', display: isMobile ? 'block' : 'inline-block' }} onClick={scrollToDiv}>Create Free Blogs Today<KeyboardDoubleArrowRightIcon /></Button>
                        </div>
                    </div>

                </div>

                <div className="container mt">
                    <div className="row" style={{ marginTop: '2%' }}>
                        <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                            <p style={{ fontWeight: 'bold', fontSize: '35px', textAlign: 'center' }}>See why hundreds of <span style={{ color: '#662D91' }}>Content Creator</span><br />and  <span style={{ color: '#662D91' }}>Entrepreneurs</span> love OceSha</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <p className="gradient-text" style={{ fontSize: '50px' }}>Testimonials</p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div onClick={prevTestimonial}><WestIcon style={{
                                display: 'flex', alignItems: 'center',
                                border: '2px solid',
                                borderImage: 'linear-gradient(to bottom right, #7407FF,#0000, #2E35D7) 1'
                            }} /></div>
                            <div onClick={nextTestimonial}><EastIcon style={{
                                display: 'flex', alignItems: 'center',
                                border: '2px solid',
                                borderImage: 'linear-gradient(to bottom right, #7407FF,#0000, #2E35D7) 1'
                            }} /></div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: isMobile ? '6%' : '' }}>
                        {displayedTestimonials.map((testimonial, index) => (
                            <div className="col-sm-12 col-md-4 col-lg-4" key={index}>
                                <div style={{
                                    display: 'flex', alignItems: 'center', border: '2px solid',
                                    borderImage: 'linear-gradient(to bottom right, #7407FF,#0000, #2E35D7) 1',
                                    borderRadius: '8px',
                                    padding: '7%'
                                }}>
                                    <img
                                        src={testimonial.image}
                                        alt={testimonial.name}
                                        style={{
                                            marginRight: '15px',
                                            width: '150px',
                                            height: '168px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <p className="gradient-text1">{testimonial.name}</p>
                                        <p style={{ fontSize: '16px', color: '#B4A5C3', textAlign: isMobile ? 'left' : 'justify' }}>"{testimonial.message + '-' + testimonial.role}"</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* FAQ */}
                <div className="container mt">
                    <div className="row" style={{ marginTop: '2%' }}>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <p style={{ color: '#fff', fontWeight: 'bold', fontSize: '48px' }}>OceSha FAQ</p>
                            <p className="custom-link" onClick={() => setViewAllFaq(true)}>View All FAQ</p>

                            <div style={{ marginTop: '6%' }}>
                                <p style={{ color: '#A38CBB', fontSize: '40px' }}>Do you have<br />
                                    any questions?</p>
                                <p style={{ color: '#A38CBB', fontSize: '16px' }}>Feel free to send us your questions or request a free consultation.</p>
                            </div>

                            <div className="row" style={{ display: 'flex', gap: '12px', marginTop: '4%', flexDirection: 'column' }}>
                                <div className="col-12" style={{ display: 'flex', gap: '4px' }}>
                                    <div className="col-6">
                                        <TextField placeholder="Enter Your Full Name" sx={{
                                            height: '45px',
                                            width: '100%',
                                            '.MuiInputBase-root': {
                                                height: '100%'
                                            }
                                        }} value={contactname} onChange={(e) => setContactName(e.target.value)}></TextField>
                                    </div>
                                    <div className="col-6">
                                        <TextField placeholder="Enter Your Email" sx={{
                                            height: '45px',
                                            width: '100%',
                                            '.MuiInputBase-root': {
                                                height: '100%'
                                            }
                                        }} value={contactemail} onChange={(e) => setContactEmail(e.target.value)}></TextField>
                                    </div>
                                </div>
                                <div className="col-12" style={{ display: 'flex', gap: '4px' }}>
                                    <div className="col-6">
                                        <TextField placeholder="Enter Your PhoneNumber" sx={{
                                            height: '45px',
                                            width: '100%',
                                            '.MuiInputBase-root': {
                                                height: '100%'
                                            }
                                        }} value={contactPhno} onChange={(e) => setContactPhNo(e.target.value)}></TextField>
                                    </div>
                                    <div className="col-6">
                                        <TextField placeholder="Let us know about your query..." sx={{
                                            height: '45px',
                                            width: '100%',
                                            '.MuiInputBase-root': {
                                                height: '100%'
                                            }
                                        }} value={contactMessage} onChange={(e) => setContactMessage(e.target.value)}></TextField>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <Button sx={{ background: '#662D91', color: '#fff', fontSize: '14px', fontWeight: 'bold', height: '45px', width: '100%' }} onClick={handleGetStartedClick}>{isContacting ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : "Get Started"}</Button>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6" style={{ display: 'flex', marginTop: '8%' }}>
                            {/* <div className="accordion" id="regularAccordionRobots">
                                <div className="rounded-2 mb-2">
                                    <h2 id="regularHeadingFirst" className="accordion-header">
                                        <button
                                            className="accordion-button accordion_style px-3"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#regularCollapseFirst"
                                            aria-expanded="true"
                                            aria-controls="regularCollapseFirst"
                                            style={{ color: '#fff' }}
                                        >
                                            What is Podcast Monster?
                                        </button>

                                    </h2>
                                    <div
                                        id="regularCollapseFirst"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="regularHeadingFirst"
                                        data-bs-parent="#regularAccordionRobots"
                                    >
                                        <div className="accordion-body">
                                            Podcast Monster is a platform that helps podcast hosts monetize and distribute their content. It generates SEO-optimized blogs from each podcast episode, allowing hosts to share content across multiple platforms and create new income streams through content creation services, ads, and affiliate links.
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {viewAllFaq ? '' :
                                <div className="customScrollbar1">
                                    <div
                                        className="scrollThumb1"
                                        style={{ top: `${scrollProgress}%` }}
                                    ></div>
                                </div>}
                            <div ref={scrollContainerRef1} className="scrollContainer1" style={{ /* backgroundColor: '#121212', */ padding: '1rem', borderRadius: '8px', height: viewAllFaq ? '' : '500px' }}>
                                {faqData.map((item, index) => (
                                    <div
                                        key={index}
                                        className="faq-card mb-3"
                                        style={{
                                            backgroundColor: '#1c1c1c',
                                            borderRadius: '8px',
                                            padding: '1rem',
                                            color: '#fff',
                                            cursor: 'pointer',
                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                                        }}
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span style={{ fontSize: '1rem' }}>{item.question}</span>
                                            <span
                                                style={{
                                                    color: '#9933FF',
                                                    fontSize: '1.5rem',
                                                    marginLeft: '1rem',
                                                }}
                                            >
                                                {openIndex === index ? '-' : '+'}
                                            </span>
                                        </div>
                                        {openIndex === index && (
                                            <div
                                                className="mt-2"
                                                style={{
                                                    fontSize: '0.9rem',
                                                    lineHeight: '1.5',
                                                    color: '#d1d1d1',
                                                }}
                                            >
                                                {item.answer}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
                {/* FAQ end */}

                {/* footer */}
                <div className="container mt" style={{
                    borderTop: '2px solid',
                    borderImage: 'linear-gradient(to bottom right, #7407FF,#0000, #2E35D7) 1'
                }}>
                    <div className="row">
                        <div
                            className="col-12"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '1%'
                            }}
                        >
                            <div className="col-4">
                                <p style={{ color: '#fff', fontSize: '15px', margin: 0 }}>
                                    Copyright <CopyRightIcon /> 2024{' '}
                                    <a
                                        href="/"
                                        style={{
                                            color: '#662D91',
                                            textDecoration: 'underline',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        ocesha.com
                                    </a>{' '}
                                    | All Rights Reserved.
                                </p>
                            </div>
                            <div className="col-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                <span>
                                    <a href="/sitemap.xml" target="_blank" rel="noopener noreferrer" /* style={{textDecoration: 'none', color: 'inherit'}} */>
                                        Sitemap
                                    </a>
                                </span>
                            </div>
                            <div className="col-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <span>
                                    <a href="/">
                                        <img
                                            src={ocesha_logo}
                                            alt="Logo"
                                            style={{ /* height: '30px', */ objectFit: 'contain' }}
                                        />
                                    </a>
                               </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* footer end */}
            </div >
        </>
    )
}

export default OceshaLanding;