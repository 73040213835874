import React, { useEffect, useState } from 'react';
import { useGetOrderSuccessQuery } from '../../redux/api/subscriptionApi';
import { Container, Stack, Typography, Button } from '@mui/material';
import { useAppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { setModule } from '../../redux/features/genieSlice';
import { useSigninUserMutation } from '../../redux/api/authApi';
import { userApi } from '../../redux/api/userApi';
import Dashboard_tm from '../traffic_monster/dashboard_tm';
import { toast } from "react-toastify";


const SuccessPage = () => {
    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);
    //console.log( urlParams.get('session_id') ); // 159


    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const campaignid = String(urlParams.get('campaignid')) || "";
    const subscription_level = urlParams.get("subscription_level") || "";
    const from_page = urlParams.get("from_page") || "";
    const module = urlParams.get("module") || "";
    const utlized_podcast_count = urlParams.get("utlized_podcast_count") || "0";
    const utlized_blog_count = urlParams.get("utlized_blog_count") || "0";
    const { data, error, isSuccess } = useGetOrderSuccessQuery({
        "session_id": String(urlParams.get('session_id')), "campaign_id": campaignid,
        "subscription_level": subscription_level, "module": module, "utlized_podcast_count": utlized_podcast_count,
        "utlized_blog_count": utlized_blog_count
    });
    console.log('success page data=>', data);
    // const [signinUser, signinState] = userApi();

    /* useEffect(()=>{
       localStorage.setItem('subscription_level', subscription_level);
       navigate('/codegenie/dashboard');
    }) */

    useEffect(() => {
        if (isSuccess) {
            localStorage.setItem('user_email', data.message.email);
            console.log('success page data1=>', data.message.email);
            //alert("inside success..1");
            localStorage.setItem("payment", "Success");
            // if (signinState.data !== undefined) {
            localStorage.setItem("subscription_level", subscription_level);

            const fromUpgrade = localStorage.getItem('fromUpgrade');
            const fromNewcampaign = localStorage.getItem('fromNewcampaign');
            const appsumoCode = localStorage.getItem('appsumo_coupon_code');
            //alert("fromNewcampaign===="+fromNewcampaign+"----from_page"+from_page)  ;
            if (appsumoCode !== '') {
                navigate('/codegenie/new-campaign');
            }else if (localStorage.getItem('blogsCount') === 'true' || from_page === 'pricing'/* && fromNewcampaign != 'true' */) {
                localStorage.setItem('activeItem', 'blogs');
                navigate('/codegenie/generated-blogs');
                toast.success('Payment Successful!');
            } else if (fromNewcampaign === 'true' || from_page == 'campaign') {
                localStorage.removeItem('fromNewcampaign');
                localStorage.setItem('campaignid', campaignid);
                localStorage.setItem('returningtocampaign', 'true');
                navigate('/codegenie/new-campaign');
            } else {
                //alert("wrong else===="+fromNewcampaign);
                navigate("/codegenie/dashboard");
            }

        }
    }, [isSuccess]);

    const getModule = urlParams.get("module");

    useEffect(() => {
        localStorage.removeItem('fromUpgrade');
        localStorage.removeItem('user_name');
    }, [])


    return (
        <>
            <Container>
                <Stack height="100vh" justifyContent="center" alignItems="center">

                    <Typography variant='h3'>Redirecting...
                    </Typography>
                    {/* <br></br>
                    <Button
                        sx={{
                            background:
                                "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                            color: "white",
                            borderRadius: "4px",
                            padding: "12px 16px",
                            height: "44px",
                        }}
                        onClick={() => {
                            // navigate("/codegenie/blogs-list");
                            navigate("/codegenie/dashboard");



                        }}
                    >
                        Dashboard
                    </Button> */}
                </Stack>
            </Container>

        </>)
}

export default SuccessPage;